import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {
	Autocomplete,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Popper,
	TextField,
} from '@mui/material';

import { Button } from '../../components';
import { listTransactionColumn } from '../../data/columnDataUpdate';
import DataTable from 'react-data-table-component';

import {
	BASE_URL,
	URL_REPORTING_UPDATE_REQ_ADD,
	URL_REPORTING_UPDATE_REQ_LIST,
} from '../../config';
import { FiRefreshCw } from 'react-icons/fi';
import { BiSpreadsheet } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import { MdSearch } from 'react-icons/md';

import notfound from '../../data/notfound.png';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import { TransactionDetail } from './TransactionDetail';
import { MatrixUtils } from '../../utils/MatrixUtils';
import _ from 'lodash';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';
import { ModalUpdateTransaction } from './ModalUpdateTransaction';
import { toast, ToastContainer } from 'react-toastify';
import { PlusOneRounded } from '@mui/icons-material';
import { BsPlus, BsPlusCircleFill } from 'react-icons/bs';
import { ModalUpdateTransactionRequest } from './ModalUpdateTransactionRequest';
import { checkIsAvailableModule } from '../../Routes';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const UpdateTransaction = () => {
	const navigate = useNavigate();

	const notify = (text) => {
		toast.success(text, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => {},
		});
	};

	const notifyError = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const {
		currentColor,
		currentAuth,
		modalOpenClose,
		screenWidth,
		privilege,
		dataLogin,
	} = useStateContext();

	const dateTo = moment().format('YYYY-MM-DD');
	const dateFrom = moment().subtract(6, 'd').format('YYYY-MM-DD');
	const maxDate = moment().subtract(1, 'months').format('YYYY-MM-01');

	// data for filter
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);
	const [pembayaran, setPembayaran] = useState('');
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const dateStarted = moment(startDate).format('YYYY-MM-DD');
	const dateEnded = moment(endDate).format('YYYY-MM-DD');

	const dateToMax = moment(startDate).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(startDate).add(1, 'months').format('YYYY-MM-DD');

	const [kategoriPembayaran, setKategoriPembayaran] = useState([]);
	const [dataCorporate, setDataCorporate] = useState([]);
	const [dataStatusCode, setDataStatusCode] = useState([]);

	const [listTrx, setListTrx] = useState([]);
	const [listTrxNew, setListTrxNew] = useState([]);

	const [dataFilterStatus, setDataFilterSttaus] = useState([
		{ value: 'APPROVED', label: 'APPROVED' },
		{ value: 'PENDING', label: 'PENDING' },
		{ value: 'REJECTED', label: 'REJECTED' },
		{ value: 'CANCELLED', label: 'CANCELLED' },
		{ value: 'FAILED', label: 'FAILED' },
	]);

	const [dataFilter, setDataFilter] = useState([
		{ value: 'noHeader', label: 'Invoice Number' },
	]);

	const [selectedFilter, setSelectedFilter] = useState(dataFilter[0]);
	const [selectedFilterStatus, setSelectedFilterStatus] =
		useState('');
	const [
		modalUpdateTransactionApproval,
		setModalUpdateTransactionApproval,
	] = useState(null);

	const [modalUpdateTransactionReq, setModalUpdateTransactionReq] =
		useState(false);

	const getSuccessTransaction = () => {
		modalOpenClose(true);

		const bodyData = {
			id: '',
			noHeader: selectedFilter.value == 'noHeader' ? search : '',
			startDate: dateStarted + ' 00:00:00',
			endDate: dateEnded + ' 23:59:59',
			status: selectedFilterStatus,
			requestedBy: '',
			approvedBy: '',
			order: 'desc', //asc, desc
			orderBy: 'date', //id, date, requestedBy, approvedBy
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_REPORTING_UPDATE_REQ_LIST +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_REPORTING_UPDATE_REQ_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.success && data.result != null) {
					let mResult = data.result;

					let resultMap = mResult.map((o) => {
						return {
							...o.oldTrxData,
							...{
								_id: o._id,
								requestStatus: o.requestStatus,
								requestedBy: o.requestedBy,
								remarks: o.remarks,
								requestedDatetime: o.requestedDatetime,
								approvedBy: o.approvedBy,
								approvedAt: o.approvedAt,
								updatedAt: o.updatedAt,
								updatedBy: o.updatedBy,
								oldDataSignature: o.oldDataSignature,
								newDataSignature: o.newDataSignature,
								newTrxData: o.newExpectedResultTrx,
							},
						};
					});
					setListTrx(resultMap);
					setListTrxNew(mResult);
					modalOpenClose(false);
				} else {
					setListTrx([]);
					setListTrxNew([]);
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				setListTrx([]);
				setListTrxNew([]);
				modalOpenClose(false);
			});
	};

	const setRequestUpdateTransaction = (data) => {
		if (checkIsAvailableModule('TRX_UPDATE_REQUEST_ADD', privilege)) {
			modalOpenClose(true);

			console.log('data', data);
			const bodyData = {
				noHeader: data.noHeader,
				newStatus: data?.selectedStatus ?? '', //cuma bisa SUCCESS/PENDING/CANCELLED/FAILED
				remarks: data?.remarks,
			};

			let mTimeStamp = getTimeStamp();
			let bodyEncripted = encryptSHA512(
				JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
			);

			let dataPLain =
				'POST' +
				'||' +
				URL_REPORTING_UPDATE_REQ_ADD +
				'||' +
				(dataLogin.username ?? '') +
				'||' +
				bodyEncripted +
				'||' +
				mTimeStamp;

			let signature = encryptHmac512Base64(dataPLain);

			fetch(BASE_URL + URL_REPORTING_UPDATE_REQ_ADD, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${currentAuth}`,
					'X-MKP-Timestamp': mTimeStamp,
					'X-MKP-Signature': signature,
				},
				body: JSON.stringify(bodyData),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					modalOpenClose(false);

					if (data.success) {
						notify(data.message);
						getSuccessTransaction();
					} else {
						notifyError(data.message);
						console.log('error', data.message);
					}
				})
				.catch((err) => {
					console.log('err =>', err);
					modalOpenClose(false);
					notifyError(err.message);
				});
		} else {
			notifyError('Feature not allowed');
		}
	};

	const onChangePembayaran = (event, values) => {
		setSelectedFilterStatus(values.value);
	};

	const onChangeSelectedFilter = (event, values) => {
		setKeyword('');
		setSelectedFilter(values);
	};

	const handleClick = () => {
		getSuccessTransaction();
	};

	const searchData = () => {
		setSearch(keyword);
	};

	const dataCategory = [
		{
			value: '',
			label: '- All Status -',
		},
	];
	{
		dataFilterStatus.map((item) => {
			let dataValue = {
				value: item.value,
				label: item.label,
			};

			dataCategory.push(dataValue);
		});
	}

	useEffect(() => {
		getSuccessTransaction();
	}, [search]);

	return (
		<div className={` `}>
			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
			<div
				className={` h-fit m-2 mt-16 md:m-10 p-6 md:p-10 mb-0 md:rounded-3xl bg-white max-w-screen md:max-w-full`}
			>
				{/* <Header category={"Filter Data"} /> */}
				<div className=" ">
					<p className=" text-base font-semibold mb-7 ">
						Filter Data
					</p>
					<div className=" md:flex gap-5 mb-5  ">
						{/* start date input */}
						<div className=" mb-5 md:mb-0 w-full">
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									className=" w-full"
									label="Start Date"
									value={startDate}
									onChange={(newValue) => {
										setStartDate(newValue);
										setEndDate(newValue);
									}}
									inputFormat="DD-MM-YYYY"
									maxDate={dateTo}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						{/* end date input */}
						<div className=" mb-5 md:mb-0 w-full">
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									className=" w-full"
									label="Until Date"
									value={endDate}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									inputFormat="DD-MM-YYYY"
									minDate={startDate}
									maxDate={dateToMax}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						<div className=" w-full">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								disableClearable
								id="jenis-user"
								options={dataCategory}
								defaultValue={dataCategory[0]}
								onChange={onChangePembayaran}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Request Status"
									/>
								)}
							/>
						</div>
					</div>
					<div className="flex items-end justify-end">
						<Button
							className={'self-end'}
							customFunc={() => handleClick()}
							bgColor={currentColor}
							color="white"
							text="Filter"
							borderRadius={'10px'}
							icon={<FiRefreshCw />}
						/>
					</div>
				</div>
			</div>

			{/* bottom container */}
			<div
				className={` h-fit   md:m-10 p-6 md:p-10  md:rounded-3xl bg-white `}
			>
				<div className=" flex justify-between mb-5 flex-wrap items-center">
					<div className=" flex items-center flex-wrap gap-4 mt-5 mb-4">
						<div className="flex items-center justify-start">
							<p className=" text-2xl">
								<BiSpreadsheet />
							</p>
							<p className=" font-semibold uppercase">
								Update Transaction Request
							</p>
						</div>
						{checkIsAvailableModule(
							'TRX_UPDATE_REQUEST_ADD',
							privilege
						) && (
							<Button
								className={'lg:self-end sm:w- sm:flex-1'}
								customFunc={() => setModalUpdateTransactionReq(true)}
								bgColor={currentColor}
								color="white"
								text="Add"
								borderRadius={'10px'}
								icon={<BsPlusCircleFill fontSize={'medium'} />}
							/>
						)}
					</div>
					<div className=" lg:w-1/2 sm:w-full sm:mt-3 mb-2 flex end">
						<div className=" w-full mr-2">
							<Autocomplete
								required
								size="small"
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="status-trx"
								options={dataFilter}
								defaultValue={dataFilter[0]}
								onChange={onChangeSelectedFilter}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Filter Field"
									/>
								)}
							/>
						</div>
						<FormControl className=" w-full" variant="outlined">
							<OutlinedInput
								size="small"
								notched
								id="outlined-adornment-password"
								type={'text'}
								placeholder="Search"
								onChange={(text) => setKeyword(text.target.value)}
								value={keyword}
								defaultValue={keyword}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										searchData();
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => searchData()}
											edge="end"
										>
											<MdSearch />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
				</div>

				<DataTable
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					columns={listTransactionColumn(
						listTrxNew,
						dataStatusCode,
						(row, isVoid = false) => {
							if (!isVoid) setModalUpdateTransactionApproval(row);
						},
						privilege
					)}
					data={listTrx}
					pagination
					paginationRowsPerPageOptions={[20, 30, 50, 100]}
					theme="solarized"
					customStyles={customStyles}
				/>
			</div>

			<ModalUpdateTransaction
				onCancel={() => {
					setModalUpdateTransactionApproval(null);
				}}
				isModal={modalUpdateTransactionApproval != null}
				data={modalUpdateTransactionApproval}
				onModalConfirm={(status, message) => {
					setModalUpdateTransactionApproval(null);

					if (status) {
						notify(message);
						getSuccessTransaction();
					} else {
						notifyError(message);
						console.log('error', message);
					}
				}}
			/>

			<ModalUpdateTransactionRequest
				onCancel={() => {
					setModalUpdateTransactionReq(null);
				}}
				isModal={modalUpdateTransactionReq}
				onModalConfirm={(selectedConfirm) => {
					setModalUpdateTransactionReq(null);
					setRequestUpdateTransaction(selectedConfirm);
				}}
			/>
		</div>
	);
};

export default UpdateTransaction;
