import React, { useState } from 'react';
import {
	MdEdit,
	MdOutlineRestoreFromTrash,
	MdOutlineRestorePage,
	MdRemoveRedEye,
	MdAttachMoney,
} from 'react-icons/md';
import { HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useStateContext } from '../contexts/ContextProvider';
import Modal from '@mui/material/Modal';
import { Button } from '../components';
import ToggleText from '../components/ToggleText';
import { checkIsAvailableModule } from '../Routes';
import { Tooltip } from '@mui/material';

var CryptoJS = require('crypto-js');

//List Transaction Column
export const listTransactionColumn = (
	statusData,
	onSelected,
	privilege,
	isVoidDebit = false
) => {
	let m = [
		...[
			{
				name: 'Status', // use template
				selector: 'paymentStatus',
				sortable: true,
				cell: (row) => (
					<button
						style={
							row.paymentStatus === 1
								? { background: '#E8F5E9' }
								: row.paymentStatus === 2
								? { background: '#FFF3E0' }
								: { background: '#FFF3E0' }
						}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						{row.paymentStatus === 1 ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								{row.paymentStatusDesc}
							</p>
						) : row.paymentStatus === 2 ? (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								{row.paymentStatusDesc}
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#F5574C' }}
							>
								{row.paymentStatusDesc}
							</p>
						)}
					</button>
				),
				width: 150,
			},
			{
				name: 'Invoice Number',
				selector: 'noHeader',
				sortable: true,
				width: 350,
			},
			{
				name: 'Merchant Reference',
				selector: 'merchantNoRef',
				sortable: true,
				width: 300,
			},
			{
				name: 'Created Date',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[0] : ''}</p>
					</div>
				),
			},
			{
				name: 'Created Time',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[1] : ''}</p>
					</div>
				),
			},
			{
				name: 'Amount',
				selector: 'paymentAmount',
				sortable: true,
				width: 180,
				cell: (row) => (
					<div className="flex flex-row justify-center align-items-center">
						{/* <MdAttachMoney color="green" size={20} className="mr-1" /> */}
						<p>Rp. {row.paymentAmount.format()}</p>
					</div>
				),
			},
			{
				name: 'Corporate',
				selector: 'corporateCID',
				sortable: true,
				width: 300,
				cell: (row) => (
					<div>
						<p>{row.corporateCID + ' - ' + row.corporateName}</p>
					</div>
				),
			},
			{
				name: 'Payment Category',
				selector: 'paymentCategoryName',
				sortable: true,
				width: 200,
			},
		],
		...(checkIsAvailableModule('REPORT_DETAIL_ACQUIRING', privilege)
			? [
					{
						name: 'Acquiring Code',
						selector: 'acquiringCode',
						sortable: true,
						width: 200,
					},
					{
						name: 'Acquiring Name',
						selector: 'acquiringName',
						sortable: true,
						width: 200,
					},
			  ]
			: []),
		...[
			{
				name: 'Payment Name',
				selector: 'paymentMethodName',
				sortable: true,
				width: 200,
			},
			{
				name: 'Settle. Dest.',
				selector: 'settlementDestination',
				sortable: true,
				width: 150,
			},
			{
				name: 'Settle Status',
				selector: 'statusSettlement',
				sortable: false,
				cell: (row) => (
					<button
						style={
							row.statusSettlement === true
								? { background: '#E8F5E9' }
								: { background: '#FFF3E0' }
						}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						{row.statusSettlement === true ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								Yes
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								No
							</p>
						)}
					</button>
				),
				width: 160,
			},
			{
				name: 'Acq Settle Status',
				selector: 'acquiringSettlementStatus',
				sortable: false,
				cell: (row) => (
					<button
						style={
							row.acquiringSettlementStatus === true
								? { background: '#E8F5E9' }
								: { background: '#FFF3E0' }
						}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						{row.acquiringSettlementStatus === true ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								Yes
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								No
							</p>
						)}
					</button>
				),
				width: 160,
			},
			{
				name: 'Prinpciple Ref',
				selector: 'responseNoRef',
				sortable: true,
				width: 350,
				cell: (row) => (
					<ToggleText
						minToggle={100}
						text={row.responseNoRef ? row.responseNoRef : ''}
					/>
				),
			},
			{
				name: 'Device ID',
				selector: 'deviceId',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText
						minToggle={18}
						text={row.deviceId ? row.deviceId : ''}
					/>
				),
			},
			{
				name: 'MID',
				selector: 'bankMID',
				sortable: true,
				width: 150,
			},
			{
				name: 'TID',
				selector: 'bankTID',
				sortable: true,
				width: 140,
			},
			{
				name: 'Card Pan',
				selector: 'cardPan',
				sortable: true,
				width: 180,
			},
			{
				name: 'Card Type',
				selector: 'cardType',
				sortable: true,
				width: 180,
			},
			{
				name: 'STAN',
				selector: 'stan',
				sortable: false,
				width: 180,
			},
			{
				name: 'RRN',
				selector: 'rrn',
				sortable: false,
				width: 180,
			},
			{
				name: 'Approval Code',
				selector: 'bankApprovalCode',
				sortable: false,
				width: 180,
			},
			{
				name: 'On Us / Off Us',
				selector: 'cardTypeTrx',
				sortable: false,
				width: 180,
			},
			{
				name: 'MDR',
				selector: 'paymentMDR',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.paymentMDR.format()}</p>
					</div>
				),
			},
		],
		...(checkIsAvailableModule(
			'REPORT_DETAIL_MDR_DKI_MKP_1',
			privilege
		) ||
		checkIsAvailableModule('REPORT_DETAIL_MDR_DKI_MKP_2', privilege)
			? [
					{
						name: 'MDR DKI',
						selector: 'paymentMDR',
						sortable: true,
						width: 200,
						cell: (row) => {
							if (
								checkIsAvailableModule(
									'REPORT_DETAIL_MDR_DKI_MKP_1',
									privilege
								) ||
								(checkIsAvailableModule(
									'REPORT_DETAIL_MDR_DKI_MKP_2',
									privilege
								) &&
									row.acquiringCode != '10004')
							) {
								let mresult = '0';

								if (
									row.paymentCategoryName == 'DEBIT' &&
									row.cardTypeTrx.toLowerCase() == 'on us'
								) {
									mresult = (
										(0.06 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'DEBIT' &&
									row.cardTypeTrx.toLowerCase() == 'off us'
								) {
									mresult = (
										(0.16 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (row.paymentCategoryName == 'QRIS') {
									mresult = (
										(0.11 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'CC' &&
									row.cardTypeTrx.toLowerCase() == 'on us'
								) {
									mresult = (
										(0.04 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'CC' &&
									row.cardTypeTrx.toLowerCase() == 'off us'
								) {
									mresult = (
										(0.06 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								}

								return (
									<div>
										<p>Rp.{mresult}</p>
									</div>
								);
							} else if (
								checkIsAvailableModule(
									'REPORT_DETAIL_MDR_DKI_MKP_2',
									privilege
								) &&
								row.acquiringCode == '10004'
							) {
								let mresult = '0';

								if (
									row.paymentCategoryName == 'DEBIT' &&
									row.cardTypeTrx.toLowerCase() == 'on us'
								) {
									mresult = (
										(100 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'DEBIT' &&
									row.cardTypeTrx.toLowerCase() == 'off us'
								) {
									mresult = (
										(0.39 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (row.paymentCategoryName == 'QRIS') {
									mresult = (
										(39 / 100) *
										(row.paymentMDR ?? 0)
									).format();
								}

								return (
									<div>
										<p>Rp.{mresult}</p>
									</div>
								);
							}
						},
					},
					{
						name: 'MDR MKP',
						selector: 'paymentMDR',
						sortable: true,
						width: 200,
						cell: (row) => {
							if (
								checkIsAvailableModule(
									'REPORT_DETAIL_MDR_DKI_MKP_1',
									privilege
								) ||
								(checkIsAvailableModule(
									'REPORT_DETAIL_MDR_DKI_MKP_2',
									privilege
								) &&
									row.acquiringCode != '10004')
							) {
								let mresult = '0';

								if (
									row.paymentCategoryName == 'DEBIT' &&
									row.cardTypeTrx.toLowerCase() == 'on us'
								) {
									mresult = (
										(0.09 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'DEBIT' &&
									row.cardTypeTrx.toLowerCase() == 'off us'
								) {
									mresult = (
										(0.23 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (row.paymentCategoryName == 'QRIS') {
									mresult = (
										(0.16 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'CC' &&
									row.cardTypeTrx.toLowerCase() == 'on us'
								) {
									mresult = (
										(0.06 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								} else if (
									row.paymentCategoryName == 'CC' &&
									row.cardTypeTrx.toLowerCase() == 'off us'
								) {
									mresult = (
										(0.09 / 100) *
										(row.paymentAmount ?? 0)
									).format();
								}

								return (
									<div>
										<p>Rp.{mresult}</p>
									</div>
								);
							} else if (
								checkIsAvailableModule(
									'REPORT_DETAIL_MDR_DKI_MKP_2',
									privilege
								)
							) {
								return (
									<div>
										<p>Rp. {(0).format()}</p>
									</div>
								);
							}
						},
					},
			  ]
			: []),
		...[
			{
				name: 'Service Fee',
				selector: 'serviceFee',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.serviceFee.format()}</p>
					</div>
				),
			},
			{
				name: 'Payment Fee',
				selector: 'paymentFee',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.paymentFee.format()}</p>
					</div>
				),
			},
			{
				name: 'Vendor Fee',
				selector: 'vendorFee',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.vendorFee.format()}</p>
					</div>
				),
			},
			{
				name: 'Description',
				selector: 'description',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText minToggle={150} text={row.description ?? ''} />
				),
			},
			{
				name: 'Status Description',
				selector: '',
				sortable: true,
				width: 140,
				cell: (row) => {
					let mFilter = statusData.filter(
						(m) => m.errorsCode == row.statusCode
					);
					return (
						<ToggleText
							minToggle={18}
							text={
								mFilter.length > 0
									? mFilter[0].errorsReadable
									: 'Unknown'
							}
						/>
					);
				},
			},
		],
	];

	if (
		checkIsAvailableModule('REPORT_DETAIL_TRANSACTION', privilege) ||
		(checkIsAvailableModule('REPORT_VOID_DEBIT', privilege) &&
			isVoidDebit)
	) {
		m.unshift({
			name: 'Action', //useTemplate
			selector: '',
			sortable: true,
			width: 100,
			cell: (row) => ListTrxAction(row, onSelected, privilege),
		});
	}

	return m;
};

const ListTrxAction = (row, onSelected, privilege) => {
	return (
		<div>
			{checkIsAvailableModule(
				'REPORT_DETAIL_TRANSACTION',
				privilege
			) && (
				<Tooltip title="SEE DETAILS">
					<button
						className=" text-2xl text-blue-500"
						onClick={() => onSelected(row.id)}
					>
						<MdRemoveRedEye />
					</button>
				</Tooltip>
			)}

			{checkIsAvailableModule('REPORT_VOID_DEBIT', privilege) &&
				(row.paymentCode == '10004' || row.paymentCode == '10005') &&
				row.statusCode == '101' && (
					<Tooltip title="VOID TRANSACTION">
						<button
							className=" text-2xl text-red-500 ml-3"
							onClick={() => onSelected(row.id, true)}
						>
							<MdOutlineRestorePage />
						</button>
					</Tooltip>
				)}
		</div>
	);
};

// list Product from Transaction
export const listTrxProductDetail = [
	{
		name: 'Kode Produk',
		selector: 'kodeProduk',
		sortable: false,
		width: 130,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Nama Produk',
		selector: 'namaProduk',
		sortable: false,
		width: 200,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Kategori',
		selector: 'kategori',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Paket',
		selector: 'paket',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Harga Jual',
		selector: 'hargaJual',
		sortable: false,
		width: 140,
		cell: (row, index) => (
			<p className="">Rp. {row.hargaJual.format()}</p>
		),
	},
	{
		name: 'Diskon',
		selector: 'diskon',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Kuantitas',
		selector: 'qty',
		sortable: false,
		width: 110,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Satuan',
		selector: 'satuan',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Service Fee',
		selector: 'serviceFee',
		sortable: false,
		width: 130,
		cell: (row, index) => (
			<p className=" w-auto">Rp. {row.serviceFee.format()}</p>
		),
	},
];

// list produk column
export const produkColumn = [
	{
		name: 'Action', //useTemplate
		selector: '',
		sortable: true,
		width: 100,
		cell: (row) => ProductAction(row),
	},
	{
		name: 'Kode Produk',
		selector: 'kodeProduk',
		sortable: true,
		width: 130,
	},
	{
		name: 'Kategori Produk',
		selector: 'namaKategoriProduk',
		sortable: true,
		width: 180,
	},
	{
		name: 'Nama Produk',
		selector: 'nama',
		sortable: true,
		width: 200,
	},
	{
		name: 'Harga Jual',
		selector: 'hargaJual',
		sortable: true,
		width: 130,
		cell: (row) => (
			<div>
				<p>Rp. {row.hargaJual.format()}</p>
			</div>
		),
	},
	{
		name: 'Harga Rombongan',
		selector: 'hargaRombongan',
		sortable: true,
		width: 180,
		cell: (row) => (
			<div>
				<p>Rp. {row.hargaRombongan.format()}</p>
			</div>
		),
	},
	{
		name: 'Min. Rombongan',
		selector: 'minRombongan',
		sortable: true,
		width: 160,
	},
	{
		name: 'Gambar',
		selector: 'gambar',
		sortable: true,
		width: 110,
	},
	{
		name: 'Jenis', //use template
		selector: 'jenis',
		sortable: true,
		width: 140,
		cell: (row) => (
			<button
				style={
					row.jenis === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl`}
			>
				{row.jenis === true ? (
					<p className=" font-semibold" style={{ color: '#3AA3F4' }}>
						Ticket
					</p>
				) : (
					<p className=" font-semibold" style={{ color: '#8E7064' }}>
						Non-Ticket
					</p>
				)}
			</button>
		),
	},
	{
		name: 'Corporate',
		selector: 'namaCorporate',
		sortable: true,
		width: 200,
	},
	{
		name: 'Satuan',
		selector: 'namaSatuanProduk',
		sortable: true,
		width: 110,
	},
	{
		name: 'Status Paket',
		selector: 'statusPaket', //usetemplate
		sortable: true,
		width: 140,
		cell: (row) => (
			<button
				style={
					row.statusPaket === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl`}
			>
				{row.statusPaket === true ? (
					<p className=" font-semibold" style={{ color: '#3AA3F4' }}>
						Ticket
					</p>
				) : (
					<p className=" font-semibold" style={{ color: '#8E7064' }}>
						Non-Ticket
					</p>
				)}
			</button>
		),
	},
	{
		name: 'Status Stok',
		selector: 'statusStok', //usetemplate
		sortable: true,
		width: 140,
		cell: (row) => (
			<button
				style={
					row.statusStok === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl`}
			>
				{row.statusStok === true ? (
					<p className=" font-semibold" style={{ color: '#3AA3F4' }}>
						On
					</p>
				) : (
					<p className=" font-semibold" style={{ color: '#8E7064' }}>
						Off
					</p>
				)}
			</button>
		),
	},
	{
		name: 'Current Stok',
		selector: 'currentStok',
		sortable: true,
		width: 130,
	},
];

export const produkColumnNoAction = [
	{
		name: 'Kode Produk',
		selector: 'kodeProduk',
		sortable: true,
		width: 130,
	},
	{
		name: 'Kategori Produk',
		selector: 'namaKategoriProduk',
		sortable: true,
		width: 180,
	},
	{
		name: 'Nama Produk',
		selector: 'nama',
		sortable: true,
		width: 200,
	},
	{
		name: 'Harga Jual',
		selector: 'hargaJual',
		sortable: true,
		width: 130,
		cell: (row) => (
			<div>
				<p>Rp. {row.hargaJual.format()}</p>
			</div>
		),
	},
	{
		name: 'Harga Rombongan',
		selector: 'hargaRombongan',
		sortable: true,
		width: 180,
		cell: (row) => (
			<div>
				<p>Rp. {row.hargaRombongan.format()}</p>
			</div>
		),
	},
	{
		name: 'Min. Rombongan',
		selector: 'minRombongan',
		sortable: true,
		width: 160,
	},
	{
		name: 'Gambar',
		selector: 'gambar',
		sortable: true,
		width: 110,
	},
	{
		name: 'Jenis', //use template
		selector: 'jenis',
		sortable: true,
		width: 140,
		cell: (row) => (
			<button
				style={
					row.jenis === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl`}
			>
				{row.jenis === true ? (
					<p className=" font-semibold" style={{ color: '#3AA3F4' }}>
						Ticket
					</p>
				) : (
					<p className=" font-semibold" style={{ color: '#8E7064' }}>
						Non-Ticket
					</p>
				)}
			</button>
		),
	},
	{
		name: 'Corporate',
		selector: 'namaCorporate',
		sortable: true,
		width: 200,
	},
	{
		name: 'Satuan',
		selector: 'namaSatuanProduk',
		sortable: true,
		width: 110,
	},
	{
		name: 'Status Paket',
		selector: 'statusPaket', //usetemplate
		sortable: true,
		width: 140,
		cell: (row) => (
			<button
				style={
					row.statusPaket === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl`}
			>
				{row.statusPaket === true ? (
					<p className=" font-semibold" style={{ color: '#3AA3F4' }}>
						Ticket
					</p>
				) : (
					<p className=" font-semibold" style={{ color: '#8E7064' }}>
						Non-Ticket
					</p>
				)}
			</button>
		),
	},
	{
		name: 'Status Stok',
		selector: 'statusStok', //usetemplate
		sortable: true,
		width: 140,
		cell: (row) => (
			<button
				style={
					row.statusStok === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl`}
			>
				{row.statusStok === true ? (
					<p className=" font-semibold" style={{ color: '#3AA3F4' }}>
						On
					</p>
				) : (
					<p className=" font-semibold" style={{ color: '#8E7064' }}>
						Off
					</p>
				)}
			</button>
		),
	},
	{
		name: 'Current Stok',
		selector: 'currentStok',
		sortable: true,
		width: 130,
	},
];

const ListProdukAction = (row) => {
	const navigate = useNavigate();
	return (
		<div>
			<button
				className=" text-2xl text-blue-500"
				onClick={() => navigate(`/transaction-detail/${row.id}`)}
			>
				<MdRemoveRedEye />
			</button>
		</div>
	);
};

const ProductAction = (row) => {
	const { currentAuth, privilege } = useStateContext();

	// Encrypt
	const ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(row.id),
		'nasirames'
	).toString();

	var enc = ciphertext
		.replace(/\+/g, 'p1L2u3S')
		.replace(/\//g, 's1L2a3S4h')
		.replace(/=/g, 'e1Q2u3A4l');

	const editProduct = privilege.some((item) => {
		return item.menu === 'Produk' && item.task === 'Update';
	});

	const deleteProduct = privilege.some((item) => {
		return item.menu === 'Produk' && item.task === 'Delete';
	});

	const notify = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
			onClose: () => window.location.reload(),
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
			// onClose: () => navigate(-1),
		});
	};
	const navigate = useNavigate();
	const deleteData = (id) => {
		const inputData = {
			id: parseInt(id),
		};
		fetch(BASE_URL + '/produk/remove', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				// 'Authorization' : `Bearer ${token}`
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					notify(json.message);
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};
	const clicked = () => {
		alert('MKP E TICKETING');
	};

	const [modal, setModal] = useState(false);
	return (
		<div className="flex gap-2">
			{editProduct ? (
				<button
					onClick={() => navigate(`/update-produk/${enc}`)}
					type="button"
					className=" text-2xl text-green-500 hover:drop-shadow-xl"
				>
					<MdEdit />
				</button>
			) : (
				''
			)}

			{deleteProduct ? (
				<button
					onClick={() => setModal(true)}
					className=" text-2xl text-red-500 hover:drop-shadow-xl"
				>
					<HiTrash />
				</button>
			) : (
				''
			)}
			<Modal onBackdropClick={() => setModal(false)} open={modal}>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold md:text-xl">
						This data will be deleted, <br />
						are you sure?
					</p>
					<div className=" flex justify-center gap-5 mt-5 ">
						<Button
							customFunc={() => setModal(false)}
							bgColor={'gray'}
							borderRadius={'10px'}
							color="white"
							text="Cancel"
							// icon={<MdArrowBack />}
						/>
						<Button
							customFunc={() => deleteData(row.id)}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Continue"
							color={'white'}
							// icon={<MdArrowBack />}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

// list corporate Column
export const corpColumn = [
	{
		name: 'Action', //useTemplate
		selector: '',
		sortable: true,
		width: 100,
		cell: (row) => CorpAction(row),
	},
	{
		name: 'CID',
		selector: 'cid',
		sortable: true,
		width: 130,
	},
	{
		name: 'Uraian',
		selector: 'uraian',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Kota',
		selector: 'namaKota',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Provinsi',
		selector: 'namaProvinsi',
		sortable: true,
		width: 160,
	},
	{
		name: 'Alamat',
		selector: 'alamat',
		sortable: true,
		width: 250,
	},
	{
		name: 'Telepon',
		selector: 'telepon',
		sortable: true,
		width: 130,
	},
	{
		name: 'Level',
		selector: 'level',
		sortable: true,
		width: 130,
	},
	{
		name: 'Gambar',
		selector: 'gambar',
		sortable: true,
		width: 130,
	},
	{
		name: 'Hirarki',
		selector: 'hirarkiId',
		sortable: true,
		width: 400,
	},
	{
		name: 'IP Local Server',
		selector: 'ipLocalServer',
		sortable: true,
		width: 160,
	},
	{
		name: 'Satuan Percentage',
		selector: 'isPrecentage',
		sortable: true,
		width: 160,
		cell: (row) => (
			<button
				type="button"
				// style={{background:'red'}}
				style={
					row.isPercentage === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl ${
					row.ispercentage === true ? '' : ''
				}`}
			>
				{row.isPercentage === true ? (
					<p style={{ color: '#2699F3' }}> Presentase</p>
				) : (
					<p style={{ color: '#826054' }}>Rupiah</p>
				)}
			</button>
		),
	},
];

export const corpColumnNoAction = [
	{
		name: 'CID',
		selector: 'cid',
		sortable: true,
		width: 130,
	},
	{
		name: 'Uraian',
		selector: 'uraian',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Kota',
		selector: 'namaKota',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Provinsi',
		selector: 'namaProvinsi',
		sortable: true,
		width: 160,
	},
	{
		name: 'Alamat',
		selector: 'alamat',
		sortable: true,
		width: 250,
	},
	{
		name: 'Telepon',
		selector: 'telepon',
		sortable: true,
		width: 130,
	},
	{
		name: 'Level',
		selector: 'level',
		sortable: true,
		width: 130,
	},
	{
		name: 'Gambar',
		selector: 'gambar',
		sortable: true,
		width: 130,
	},
	{
		name: 'Hirarki',
		selector: 'hirarkiId',
		sortable: true,
		width: 400,
	},
	{
		name: 'IP Local Server',
		selector: 'ipLocalServer',
		sortable: true,
		width: 160,
	},
	{
		name: 'Satuan Percentage',
		selector: 'isPrecentage',
		sortable: true,
		width: 160,
		cell: (row) => (
			<button
				type="button"
				// style={{background:'red'}}
				style={
					row.isPercentage === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl ${
					row.ispercentage === true ? '' : ''
				}`}
			>
				{row.isPercentage === true ? (
					<p style={{ color: '#2699F3' }}> Presentase</p>
				) : (
					<p style={{ color: '#826054' }}>Rupiah</p>
				)}
			</button>
		),
	},
];

const CorpAction = (row) => {
	const { currentAuth, privilege } = useStateContext();

	// Encrypt
	const ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(row.id),
		'nasirames'
	).toString();

	var enc = ciphertext
		.replace(/\+/g, 'p1L2u3S')
		.replace(/\//g, 's1L2a3S4h')
		.replace(/=/g, 'e1Q2u3A4l');

	const editCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Update';
	});

	const deleteCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Delete';
	});

	const notify = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
			onClose: () => window.location.reload(),
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
			// onClose: () => navigate(-1),
		});
	};
	const navigate = useNavigate();
	const deleteData = (id) => {
		const inputData = {
			id: parseInt(id),
		};
		fetch(BASE_URL + '/corporate/remove', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				// 'Authorization' : `Bearer ${token}`
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					notify(json.message);
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};
	const clicked = () => {
		alert('MKP E TICKETING');
	};

	const [modal, setModal] = useState(false);
	return (
		<div className="flex gap-2">
			<button
				type="button"
				onClick={() => navigate(`/update-corp/${enc}`)}
				className=" text-2xl text-green-500 hover:drop-shadow-xl"
			>
				<MdEdit />
			</button>

			<button
				disabled={row.id === 1 ? true : false}
				// onClick={() => alert(encryptedString)}
				onClick={() => setModal(true)}
				className=" text-2xl text-red-500 hover:drop-shadow-xl"
			>
				<HiTrash />
			</button>

			<Modal onBackdropClick={() => setModal(false)} open={modal}>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold text-base md:text-xl">
						This data will be deleted, <br />
						are you sure?
					</p>
					<div className=" flex justify-center gap-5 mt-5 ">
						<Button
							customFunc={() => setModal(false)}
							bgColor={'gray'}
							borderRadius={'10px'}
							color="white"
							text="Cancel"
							// icon={<MdArrowBack />}
						/>
						<Button
							customFunc={() => deleteData(row.id)}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Continue"
							color={'white'}
							// icon={<MdArrowBack />}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
