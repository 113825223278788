// sandbox Dev API
// export const BASE_URL =
// 	'https://sandbox.mkpmobile.com/api/a2pdashboard';

// export const BASE_URL_ONLINE =
// 	'https://sandbox.mkpmobile.com/api/apps2payonline';

// export const BASE_URL_SETTLEMENT =
// 	'https://sandbox.mkpmobile.com/api/a2pbpdmalukusync';

// export const SECRET_KEY_URL =
// 	'https://sandbox.mkpmobile.com/api/apps2payonline/public/corporate/partner';

// prod API
export const BASE_URL =
	'https://apipayment.mkpmobile.com/a2pdashboard';

export const BASE_URL_ONLINE =
	'https://apipayment.mkpmobile.com/apps2payonline';

export const BASE_URL_SETTLEMENT =
	'https://apipayment.mkpmobile.com/a2pbpdmalukusync';

export const SECRET_KEY_URL =
	'https://apipayment.mkpmobile.com/apps2payonline/public/corporate/partner';

export const URL_LOGIN = '/login/login2';

export const URL_DASHBOARD_HEADER = '/dashboard/header';
export const URL_DASHBOARD_HEADER_V2 = '/dashboard/header/v2';
export const URL_DASHBOARD_REVENUE = '/dashboard/revenue';
export const URL_DASHBOARD_REVENUE_V2 = '/dashboard/revenue/v2';

export const URL_KATEGORI_LIST = '/kategoripembayaran/list';
export const URL_KATEGORI_REMOVE = '/kategoripembayaran/remove';
export const URL_KATEGORI_ADD = '/kategoripembayaran/add';
export const URL_KATEGORI_EDIT = '/kategoripembayaran/edit';
export const URL_CORPORATE_SYNC_LOC = '/corporate/locations';

export const URL_AQUIRING_LIST = '/acquiring/list';
export const URL_AQUIRING_REMOVE = '/acquiring/delete';
export const URL_AQUIRING_ADD = '/acquiring/add';
export const URL_AQUIRING_EDIT = '/acquiring/update';
export const URL_AQUIRING_SYNC = '/acquiring/sync';

export const URL_GATEWAT_DEBIT_MERCHANT_LIST = '/merchant/getall';
export const URL_GATEWAT_DEBIT_MERCHANT_DETAIL = '/merchant/get';
export const URL_GATEWAT_DEBIT_MERCHANT_REMOVE = '/merchant/delete';
export const URL_GATEWAT_DEBIT_MERCHANT_ADD = '/merchant/add';
export const URL_GATEWAT_DEBIT_MERCHANT_EDIT = '/merchant/update';
export const URL_AQUIRING_WORKER_ADD = '/acquiring/worker/add';
export const URL_AQUIRING_WORKER_UPDATE = '/acquiring/worker/update';

export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_LIST =
	'/merchantacquiring/getbymerchantkey';
export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_REMOVE =
	'/merchantacquiring/delete';
export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_ADD =
	'/merchantacquiring/add';
export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_EDIT =
	'/merchantacquiring/update';

export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_LIST =
	'/merchantdevicebankacquiring/getbymerchantkeykodebank';
export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_REMOVE =
	'/merchantdevicebankacquiring/delete';
export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_ADD =
	'/merchantdevicebankacquiring/add';
export const URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_EDIT =
	'/merchantdevicebankacquiring/update';

export const URL_GATEWAT_DEBIT_SEGMEN_LIST = '/routesegmen/getall';
export const URL_GATEWAT_DEBIT_SEGMEN_REMOVE = '/routesegmen/delete';
export const URL_GATEWAT_DEBIT_SEGMEN_ADD = '/routesegmen/add';
export const URL_GATEWAT_DEBIT_SEGMEN_EDIT = '/routesegmen/update';

export const URL_GATEWAT_DEBIT_SEGMEN_DETAIL_LIST =
	'/routesegmendetail/get';
export const URL_GATEWAT_DEBIT_SEGMEN_DETAIL_REMOVE =
	'/routesegmendetail/delete';
export const URL_GATEWAT_DEBIT_SEGMEN_DETAIL_EDIT =
	'/routesegmendetail/update';
export const URL_GATEWAT_DEBIT_SEGMEN_DETAIL_ADD =
	'/routesegmendetail/add';

export const URL_ISSUING_LIST = '/issuing/list';
export const URL_ISSUING_REMOVE = '/issuing/delete';
export const URL_ISSUING_ADD = '/issuing/add';
export const URL_ISSUING_EDIT = '/issuing/edit';
export const URL_ISSUING_SYNC = '/issuing/sync';

export const URL_CARDTYPE_LIST = '/jeniskartu/getall';
export const URL_CARDTYPE_REMOVE = '/jeniskartu/delete';
export const URL_CARDTYPE_ADD = '/jeniskartu/add';
export const URL_CARDTYPE_EDIT = '/jeniskartu/update';

export const URL_ISSUING_BIN_LIST = '/bincard/list';
export const URL_ISSUING_BIN_REMOVE = '/bincard/delete';
export const URL_ISSUING_BIN_ADD = '/bincard/add';
export const URL_ISSUING_BIN_EDIT = '/bincard/edit';
export const URL_ISSUING_BIN_SYNC = '/bincard/sync';

export const URL_PAYMENTDATA_LIST = '/metodepembayaran/list';
export const URL_PAYMENTDATA_REMOVE = '/metodepembayaran/remove';
export const URL_PAYMENTDATA_ADD = '/metodepembayaran/add';
export const URL_PAYMENTDATA_EDIT = '/metodepembayaran/edit';

export const URL_ERROR_LIST = '/public/errors/list';
export const URL_ERROR_REMOVE = '/public/errors/remove/';
export const URL_ERROR_ADD = '/public/errors/add';
export const URL_ERROR_EDIT = '/public/errors/update';
export const URL_ERROR_LOG_LIST = '/public/errors-log/list/index';

export const URL_STATUS_LIST = '/public/errors/list';
export const URL_STATUS_REMOVE = '/public/errors/remove/';
export const URL_STATUS_ADD = '/public/errors/add';
export const URL_STATUS_EDIT = '/public/errors/update';

export const URL_ROLE_TASK_LIST = '/role/v2/roletask/get';
export const URL_ROLE_TASK_REMOVE = '/role/v2/roletask/delete';
export const URL_ROLE_TASK_ADD = '/role/v2/roletask/add';
export const URL_ROLE_TASK_EDIT = '/role/v2/roletask/edit';

export const URL_ROLE_LIST = '/role/v2/rolelist/get';
export const URL_ROLE_REMOVE = '/role/v2/rolelist/delete';
export const URL_ROLE_ADD = '/role/v2/rolelist/add';
export const URL_ROLE_GET = '/role/v2/roledetails/get';
export const URL_ROLE_EDIT = '/role/v2/rolelist/edit';

export const URL_DEVICE_LOG = '/device/log';

export const URL_PAYMENT_LINK_LIST = '/payment-link/requestlink/list';
export const URL_PAYMENT_LINK_LAST_RECORD =
	'/payment-link/transaction/check/last-merchantnoref';
export const URL_PAYMENT_LINK_CHECK_REF =
	'/payment-link/transaction/check/merchantnoref';

export const URL_REPORTING_MERCHANT = '/summary/merchant';
export const URL_REPORTING_LIST = '/listtrx/listnew';
export const URL_REPORTING_LIST_DEV = '/listtrx/dev/listsettlement';
export const URL_REPORTING_LIST_V2 = '/listtrx/listnew/v2';
export const URL_REPORTING_MALUKU_LIST = '/reporting/list/index';
export const URL_REPORTING_MALUKU_SYNC = '/sync/bpd-maluku/manual';
export const URL_REPORTING_MALUKU_SYNC_CRON =
	'/sync/bpd-maluku/scheduling';
export const URL_REPORTING_A2P_SYNC_CRON =
	'/sync/apps2pay/scheduling';

export const URL_REPORTING_VOID = '/listtrx/void';
export const URL_REPORTING_SYNC = '/trx/sync';

export const URL_REPORTING_UPDATE_REQ_LIST =
	'/update-trx-status/list';
export const URL_REPORTING_UPDATE_REQ_ADD =
	'/update-trx-status/request';
export const URL_REPORTING_UPDATE_REQ_APPROVED =
	'/update-trx-status/approve';
export const URL_REPORTING_UPDATE_REQ_CANCELLED =
	'/update-trx-status/cancel';
export const URL_REPORTING_UPDATE_REQ_REJECTED =
	'/update-trx-status/reject';

export const URL_GATEWAY_TRANSACTION_LIST =
	'/public/servicerouter/list';
export const URL_GATEWAY_TRANSACTION_REMOVE =
	'/public/servicerouter/remove/';
export const URL_GATEWAY_TRANSACTION_ADD =
	'/public/servicerouter/add';
export const URL_GATEWAY_TRANSACTION_EDIT =
	'/public/servicerouter/update/';
