import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Modal,
	TextField,
} from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { Button } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import { useStateContext } from '../../contexts/ContextProvider';
import { MdSave } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { paymentItemsListColumn } from './columnData';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../data/notfound.png';

export const ModalCreateConfirm = (props) => {
	const { currentColor, screenWidth } = useStateContext();

	const [modal, setModal] = useState(false);
	const [data, setData] = useState(null);
	const [pending, setPending] = useState(false);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => props.onSuccess(),
		});
	};

	const notifyErr = (message) => {
		toast.error(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const callVoidData = () => {
		props.onSubmit();
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={props.isModal}
				onClose={() => {
					setData(null);
					props.onCancel();
				}}
			>
				<DialogTitle>Data Confirmation</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ paddingBottom: 20 }}>
						<p className="text-sm" style={{ color: '#757575' }}>
							Please check the data below before sending
						</p>
					</DialogContentText>
					<Box
						noValidate
						component="form"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							m: 'auto',
							width: '100%',
						}}
					>
						<div className="w-full flex flex-col justify-start items-start gap-2">
							<p className="text-xl mt-2 mb-2">Bill To</p>
							<div className="w-full flex flex-row max-sm:flex-col sm:justify-between sm:items-center">
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex flex-col justify-start"
								>
									<p
										className="mt-1 mb-1 text-sm"
										style={{ color: '#757575' }}
									>
										Corporate Name :
									</p>
									<p className="mt-1 mb-1">
										{props.data?.customerData?.customerName ?? ''}
									</p>
								</div>
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex flex-col justify-start"
								>
									<p
										className="mt-1 mb-1 text-sm"
										style={{ color: '#757575' }}
									>
										Corporate Email :
									</p>
									<p className="mt-1 mb-1">
										{props.data?.customerData?.customerInitialEmail ??
											''}
									</p>
								</div>
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex flex-col justify-start"
								>
									<p
										className="mt-1 mb-1 text-sm"
										style={{ color: '#757575' }}
									>
										Corporate Phone Number :
									</p>
									<p className="mt-1 mb-1">
										{props.data?.customerData?.customerPhoneNumber ??
											''}
									</p>
								</div>
							</div>
						</div>

						<div className="w-full flex flex-col justify-center items-start gap-3 mt-5 mb-2">
							<p className="text-xl">Invoice Data</p>

							<div className="flex flex-row w-full flex-1 max-sm:justify-between items-center">
								<p className="text-sm" style={{ color: '#757575' }}>
									Invoice Number :
								</p>
								<p className="text-md ml-2 max-sm:text-right">
									{props.data?.merchantNoRef ?? ''}
								</p>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								background: '#F2F2FE',
								borderRadius: 10,
								padding: 10,
							}}
							className="flex-row max-sm:flex-col w-full justify-content-center align-items-center"
						>
							<div className="flex flex-1 flex-col">
								<p
									className="mt-1 mb-1 text-sm"
									style={{ color: '#757575' }}
								>
									Remarks :
								</p>
								<p className="mt-1 mb-1 text-lg">
									{props.data?.remarks ?? ''}
								</p>
							</div>
							<div className="flex flex-1 flex-col">
								<p
									className="mt-1 mb-1 text-sm text-right"
									style={{ color: '#757575' }}
								>
									Amount :
								</p>
								<p
									className="mt-1 mb-1 text-2xl text-right font-bold"
									style={{ color: '#4042CB' }}
								>
									{props.data?.amount
										? 'Rp' + props.data?.amount?.format()
										: '-'}
								</p>
								<p
									style={{ fontStyle: 'italic', color: '#757575' }}
									className="mt-1 mb-1 text-xs text-right"
								>
									{props.data?.isExclude
										? 'Note: Amount does not include MDR'
										: 'Note: Amount includes MDR'}
								</p>
							</div>
						</div>

						{props.data?.productItems &&
						props.data?.productItems.length > 0 ? (
							<div className="w-full d-flex flex-row justify-end align-items-center mt-3">
								<p className="font-weight-bold text-xl mt-2 mb-2">
									Items Data
								</p>
								<DataTable
									className=" "
									noDataComponent={
										<div className=" justify-center">
											<img
												src={notfound}
												style={{
													width: 'auto',
													height: screenWidth >= 500 ? 100 : 50,
												}}
											/>
										</div>
									}
									columns={paymentItemsListColumn(true)}
									data={
										props.data?.productItems
											? props?.data.productItems ?? []
											: []
									}
									progressPending={pending}
									progressComponent={
										<RiLoader4Line className=" animate-spin text-6xl" />
									}
									theme="solarized"
									customStyles={{
										headCells: {
											style: {
												fontSize: '14px',
												fontWeight: '700',
												paddingLeft: '0 8px',
											},
										},
										cells: {
											style: {
												paddingLeft: '0px',
												paddingRight: '8px',
											},
										},
									}}
								/>
							</div>
						) : (
							<></>
						)}

						<div className="w-full flex flex-col justify-start items-start gap-3 mt-5 mb-2">
							<p className="text-xl">Invoice Setup</p>
							<div className="w-full flex flex-row max-sm:flex-col sm:justify-between sm:items-center max-sm:gap-2">
								<div className="flex-1 flex flex-col justify-start gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Link Maximum Attemp :
									</p>
									<p className="text-sm">
										{props.data?.maxAttempt &&
										props.data?.maxAttempt != 0
											? props.data?.maxAttempt
											: '-'}
									</p>
								</div>
								<div className="flex-1 flex flex-col justify-start gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Link Expired Date Time :
									</p>
									<p className="text-sm">
										{props.data?.expiredDatetime
											? props.data?.expiredDatetime
											: '-'}
									</p>
								</div>
								<div className="flex-1 flex flex-col justify-start gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Partner Callback URL :
									</p>
									<p className="text-sm">
										{props.data?.callbackUrl
											? props.data?.callbackUrl
											: '-'}
									</p>
								</div>

								<div className="flex-1 flex flex-col justify-start gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Short URL :
									</p>
									<p className="text-sm">
										{props.data?.shortUrl
											? props.data?.shortUrl
											: '-'}
									</p>
								</div>
							</div>
						</div>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						color={'red'}
						customFunc={() => {
							setModal(false);
							setData(null);
							props.onCancel();
						}}
						text={'Cancel'}
						className={'mr-3'}
					/>
					<Button
						bgColor={currentColor}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Submit"
						type="button"
						customFunc={() => callVoidData()}
						color={'white'}
						icon={<MdSave fontSize={20} />}
					/>
				</DialogActions>
			</Dialog>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</>
	);
};
