import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, TextField } from '@mui/material';

export function ModalConfirmation(props) {
	return (
		<Dialog
			open={props.isModalOpen}
			onClose={props.onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{props.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel}>Cancel</Button>
				<Button onClick={props.onConfirm} autoFocus>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function ModalConfirmationUpdateRequest(props) {
	const [remarks, setRemarks] = React.useState('');
	const [dataFilterStatus, setDataFilterStatus] = React.useState([
		{ value: 'SUCCESS', label: 'SUCCESS' },
		{ value: 'PENDING', label: 'PENDING' },
		{ value: 'FAILED', label: 'FAILED' },
		{ value: 'CANCELLED', label: 'CANCELLED' },
	]);

	const [selectedStatus, setSelectedStatus] =
		React.useState('SUCCESS');

	const onChangePembayaran = (event, values) => {
		setSelectedStatus(values.value);
	};

	return (
		<Dialog
			fullWidth={true}
			open={props.isModalOpen}
			onClose={props.onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<div className="flex flex-col gap-2">
					<div className="flex flex-row justify-between items-center">
						<p>Invoice Number</p>
						<p>{props.modalData?.noHeader ?? ''}</p>
					</div>
					<div className="flex flex-row justify-between items-center">
						<p>Last Status</p>
						<p>{props.modalData?.paymentStatusDesc ?? ''}</p>
					</div>
					<div className="flex flex-row justify-between items-center">
						<p>Amount</p>
						<p>{props.modalData?.paymentAmount?.format() ?? ''}</p>
					</div>
					<div className="flex flex-row justify-between items-center">
						<p>Payment</p>
						<p>{props.modalData?.paymentMethodName ?? ''}</p>
					</div>
					<div className="flex flex-row justify-between items-center">
						<p>Created At</p>
						<p>{props.modalData?.createdAt ?? ''}</p>
					</div>
				</div>

				<div className="flex justify-center items-center gap-2 mt-3">
					<div className="flex-1 mt-2">
						<Autocomplete
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							disableClearable
							id="jenis-user"
							options={dataFilterStatus}
							defaultValue={selectedStatus}
							onChange={onChangePembayaran}
							renderInput={(params) => (
								<TextField
									required
									variant="outlined"
									{...params}
									label="Request Status"
								/>
							)}
						/>
					</div>
					<div className="flex-1 mt-2">
						<TextField
							className="w-full"
							required
							label="REMARKS"
							id="noHeader"
							variant="outlined"
							onChange={(text) => setRemarks(text.target.value)}
							placeholder="set remarks here.."
							InputLabelProps={{ shrink: true }}
							value={remarks}
							defaultValue={remarks}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel}>Cancel</Button>
				<Button
					onClick={() => {
						let mRemarks = remarks;
						let mSelected = selectedStatus;
						setRemarks('');
						setSelectedStatus(dataFilterStatus[0]);

						props.onConfirm({
							remarks: mRemarks,
							selectedStatus: mSelected,
							noHeader: props.modalData?.noHeader ?? '',
						});
					}}
					autoFocus
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
