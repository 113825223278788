import {
	Box,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Modal,
	Popover,
	TextField,
	Typography,
} from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { Button } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import { useStateContext } from '../../contexts/ContextProvider';
import { MdCheckCircle, MdContentCopy } from 'react-icons/md';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboard from 'react-copy-to-clipboard';
import { paymentItemsListColumn } from './columnData';
import { RiLoader4Line } from 'react-icons/ri';
import DataTable from 'react-data-table-component';
import notfound from '../../data/notfound.png';

export const ModalCreateCallback = (props) => {
	const { currentColor, screenWidth } = useStateContext();

	const [modal, setModal] = useState(false);
	const [data, setData] = useState(null);
	const [isCopied, setIsCopied] = useState(false);
	const [pending, setPending] = useState(false);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	const notify = (message) => {
		toast.info(message, {
			autoClose: 500,
			position: toast.POSITION.BOTTOM_CENTER,
		});
	};

	const notifyErr = (message) => {
		toast.error(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
		});
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={props.isModal}
				onClose={() => {
					setData(null);
					props.onCancel();
				}}
			>
				<DialogTitle color={'green'}>
					<div className="flex max-sm:flex-col max-sm:gap-2 sm:justify-between sm:items-center">
						<div className="flex-1 flex flex-col justify-start items-start gap-1">
							<div
								style={{ display: 'flex' }}
								className="justify-start items-center flex-row"
							>
								<p>INVOICE CREATED</p>
								<MdCheckCircle style={{ marginLeft: 10 }} />{' '}
							</div>
							<p
								className="text-sm font-normal"
								style={{ color: '#757575' }}
							>
								Your invoice has been successfully generated
							</p>
						</div>
						<div className="flex flex-col justify-center items-start sm:pr-10 gap-1 max-sm:mt-2">
							<p
								className="text-sm font-normal"
								style={{ color: '#757575' }}
							>
								Link Ref
							</p>
							<div className="flex flex-row max-sm:flex-row-reverse sm:justify-start max-sm:justify-between max-sm:w-full items-center gap-2">
								<CopyToClipboard
									text={
										props.data?.paymentUrl
											? props.data?.paymentUrl
											: ''
									}
									onCopy={(text, result) => {
										setIsCopied(true);
									}}
								>
									<Chip
										style={{ cursor: 'pointer' }}
										sx={{
											background: currentColor,
											'& .MuiChip-label': {
												color: 'white',
											},
											borderRadius: 2,
										}}
										icon={<MdContentCopy color="white" />}
										label="Copy Link"
									/>
								</CopyToClipboard>

								<p className="text-sm font-normal text-black">
									{props.data?.requestNo ?? ''}
								</p>
							</div>
						</div>
					</div>
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => {
						setData(null);
						props.onCancel();
					}}
					color="black"
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent style={{ paddingTop: 0 }}>
					{/* <DialogContentText style={{ paddingBottom: 20 }}>
						
					</DialogContentText> */}
					<Box
						noValidate
						component="form"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							m: 'auto',
							width: '100%',
						}}
					>
						<div className="w-full flex flex-col justify-start items-start gap-2">
							<p className="text-xl mt-2 mb-2">Bill To</p>
							<div className="w-full flex flex-row max-sm:flex-col sm:justify-between sm:items-center">
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex flex-col sm:justify-start max-sm:flex-row max-sm:justify-between max-sm:items-center"
								>
									<p
										className="mt-1 mb-1 text-sm"
										style={{ color: '#757575' }}
									>
										Corporate Name :
									</p>
									<p className="mt-1 mb-1">
										{props.data?.customerInformation?.customerName ??
											''}
									</p>
								</div>
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex flex-col sm:justify-start max-sm:flex-row max-sm:justify-between max-sm:items-center"
								>
									<p
										className="mt-1 mb-1 text-sm"
										style={{ color: '#757575' }}
									>
										Corporate Email :
									</p>
									<p className="mt-1 mb-1">
										{props.data?.customerInformation
											?.customerInitialEmail ?? ''}
									</p>
								</div>
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex flex-col sm:justify-start max-sm:flex-row max-sm:justify-between max-sm:items-center"
								>
									<p
										className="mt-1 mb-1 text-sm"
										style={{ color: '#757575' }}
									>
										Corporate Phone Number :
									</p>
									<p className="mt-1 mb-1">
										{props.data?.customerInformation
											?.customerPhoneNumber ?? ''}
									</p>
								</div>
							</div>
						</div>

						<div className="w-full flex flex-col justify-center items-start gap-3 mt-5 mb-2">
							<p className="text-xl">Invoice Data</p>

							<div className="flex flex-row w-full flex-1 items-center max-sm:justify-between">
								<p className="text-sm" style={{ color: '#757575' }}>
									Invoice Number :
								</p>
								<p className="text-md ml-2">
									{props.data?.merchantNoRef ?? ''}
								</p>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								background: '#F2F2FE',
								borderRadius: 10,
								padding: 10,
							}}
							className="flex-row max-sm:flex-col w-full justify-content-center align-items-center"
						>
							<div className="flex flex-1 flex-col">
								<p
									className="mt-1 mb-1 text-sm"
									style={{ color: '#757575' }}
								>
									Remarks :
								</p>
								<p className="mt-1 mb-1 text-lg">
									{props.data?.remarks ?? ''}
								</p>
							</div>
							<div className="flex flex-1 flex-col">
								<p
									className="mt-1 mb-1 text-sm text-right"
									style={{ color: '#757575' }}
								>
									Amount :
								</p>
								<p
									className="mt-1 mb-1 text-2xl text-right font-bold"
									style={{ color: '#4042CB' }}
								>
									{props.data?.amount
										? 'Rp' + props.data?.amount?.format()
										: '-'}
								</p>
								<p
									style={{ fontStyle: 'italic', color: '#757575' }}
									className="mt-1 mb-1 text-xs text-right"
								>
									{props.data?.isExclude
										? 'Note: Amount does not include MDR'
										: 'Note: Amount includes MDR'}
								</p>
							</div>
						</div>

						{props.data?.productItems &&
						props.data?.productItems.length > 0 ? (
							<div className="w-full d-flex flex-row justify-end align-items-center mt-3">
								<p className="font-weight-bold text-xl mt-2 mb-2">
									Items Data
								</p>
								<DataTable
									className=" "
									noDataComponent={
										<div className=" justify-center">
											<img
												src={notfound}
												style={{
													width: 'auto',
													height: screenWidth >= 500 ? 100 : 50,
												}}
											/>
										</div>
									}
									columns={paymentItemsListColumn()}
									data={
										props.data?.productItems
											? props?.data.productItems ?? []
											: []
									}
									progressPending={pending}
									progressComponent={
										<RiLoader4Line className=" animate-spin text-6xl" />
									}
									theme="solarized"
									customStyles={{
										headCells: {
											style: {
												fontSize: '14px',
												fontWeight: '700',
												paddingLeft: '0 8px',
											},
										},
										cells: {
											style: {
												paddingLeft: '0px',
												paddingRight: '8px',
											},
										},
									}}
								/>
							</div>
						) : (
							<></>
						)}

						<div className="w-full flex flex-col justify-start items-start gap-3 mt-5 mb-2">
							<p className="text-xl">Invoice Setup</p>
							<div className="w-full flex flex-row max-sm:flex-col sm:justify-between sm:items-center max-sm:gap-2">
								<div className="flex-1 flex flex-col max-sm:flex-row sm:justify-start max-sm:justify-between max-sm:items-center gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Link Maximum Attemp :
									</p>
									<p className="text-sm">
										{props.data?.maxAttempt &&
										props.data?.maxAttempt != 0
											? props.data?.maxAttempt
											: '-'}
									</p>
								</div>
								<div className="flex-1 flex flex-col max-sm:flex-row sm:justify-start max-sm:justify-between max-sm:items-center gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Link Expired Date Time :
									</p>
									<p className="text-sm">
										{props.data?.expiredPaymentLink
											? props.data?.expiredPaymentLink
											: '-'}
									</p>
								</div>
								<div className="flex-1 flex flex-col max-sm:flex-row sm:justify-start max-sm:justify-between max-sm:items-center gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Partner Callback URL :
									</p>
									<p className="text-sm">
										{props.data?.callbackUrl
											? props.data?.callbackUrl
											: '-'}
									</p>
								</div>
								<div className="flex-1 flex flex-col max-sm:flex-row sm:justify-start max-sm:justify-between max-sm:items-center gap-2">
									<p className="text-sm" style={{ color: '#757575' }}>
										Short URL :
									</p>
									<p className="text-sm">
										{props.data?.callbackUrl ? (
											<CopyToClipboard
												text={
													props.data?.callbackUrl
														? props.data?.callbackUrl
														: ''
												}
												onCopy={(text, result) => {
													setIsCopied(true);
												}}
											>
												<Chip
													style={{ cursor: 'pointer' }}
													sx={{
														background: currentColor,
														'& .MuiChip-label': {
															color: 'white',
														},
														borderRadius: 2,
													}}
													icon={<MdContentCopy color="white" />}
													label="Copy Short Link"
												/>
											</CopyToClipboard>
										) : (
											'-'
										)}
									</p>
								</div>
							</div>
						</div>
					</Box>
				</DialogContent>
				{/* <DialogActions>
					<Button
						bgColor={'#ccc'}
						customFunc={() => {
							setModal(false);
							setData(null);
							props.onCancel();
						}}
						color={'white'}
						text={'Close'}
					/>
				</DialogActions> */}
			</Dialog>

			<Popover
				open={isCopied}
				onClose={() => setIsCopied(false)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography sx={{ p: 2 }}>
					Payment Link copied to clipboard!
				</Typography>
			</Popover>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</>
	);
};
