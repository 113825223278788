import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TextField,
} from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { Button } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import CloseIcon from '@mui/icons-material/Close';
import { WarningSharp } from '@mui/icons-material';
import {
	BASE_URL,
	URL_REPORTING_UPDATE_REQ_APPROVED,
	URL_REPORTING_UPDATE_REQ_CANCELLED,
	URL_REPORTING_UPDATE_REQ_REJECTED,
} from '../../config';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';
import { ModalConfirmation } from './ModalConfirmation';
import { checkIsAvailableModule } from '../../Routes';

export const ModalUpdateTransaction = (props) => {
	const { modalOpenClose, dataLogin, currentAuth, privilege } =
		useStateContext();

	const [data, setData] = useState(null);

	const [modalConfirm, setModalConfirm] = useState(false);
	const [modalReject, setModalReject] = useState(null);
	const [addNote, setAddNote] = useState('');

	useEffect(() => {
		if (props.data) {
			console.log('DATA', props.data);
			setData(props.data);
		}
	}, [props.data]);

	const getConfirmTransaction = (status) => {
		if (checkIsAvailableModule('TRX_UPDATE_APPROVAL', privilege)) {
			setModalConfirm(false);
			setModalReject(null);
			modalOpenClose(true);

			const bodyData = {
				...{ id: data._id },
				...(status != 'success' ? { remarks: addNote } : {}),
			};

			let mTimeStamp = getTimeStamp();
			let bodyEncripted = encryptSHA512(
				JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
			);

			let mUrl = '';
			if (status == 'success') {
				mUrl = URL_REPORTING_UPDATE_REQ_APPROVED;
			} else if (status == 'cancel') {
				mUrl = URL_REPORTING_UPDATE_REQ_CANCELLED;
			} else if (status == 'reject') {
				mUrl = URL_REPORTING_UPDATE_REQ_REJECTED;
			}

			let dataPLain =
				'POST' +
				'||' +
				mUrl +
				'||' +
				(dataLogin.username ?? '') +
				'||' +
				bodyEncripted +
				'||' +
				mTimeStamp;

			let signature = encryptHmac512Base64(dataPLain);

			fetch(BASE_URL + mUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${currentAuth}`,
					'X-MKP-Timestamp': mTimeStamp,
					'X-MKP-Signature': signature,
				},
				body: JSON.stringify(bodyData),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.success && data.statusCode == '101') {
						modalOpenClose(false);
						props.onModalConfirm(true, data.message);
					} else {
						modalOpenClose(false);
						props.onModalConfirm(false, data.message);
					}
				})
				.catch((err) => {
					console.log('err =>', err);
					modalOpenClose(false);
					props.onModalConfirm(false, err.message);
				});
		} else {
			props.onModalConfirm(false, 'Feature not allowed');
		}
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={props.isModal}
				onClose={() => {
					setData(null);
					props.onCancel();
				}}
			>
				<DialogTitle color={'green'}>
					<div
						style={{ display: 'flex' }}
						className="justify-start items-center flex-row"
					>
						<p>Request Details</p>
						{/* <MdCheckCircle style={{ marginLeft: 10 }} />{' '} */}
					</div>
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => {
						setData(null);
						props.onCancel();
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText
						style={{ borderBottomWidth: 1, paddingBottom: 3 }}
						className="flex items-center"
					>
						<WarningSharp
							fontSize="small"
							color="error"
							className="mr-1"
						/>
						Make sure you have checked the following data changes.
					</DialogContentText>
					<Box
						noValidate
						component="form"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							m: 'auto',
							width: '100%',
							paddingTop: 3,
						}}
					>
						<div className="d-flex flex-col justify-end align-items-center">
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								{data != null && (
									<table className="table table-auto table-responsive table-bordered w-full">
										<thead>
											<th className="text-left">Data</th>
											<th className="text-left">Last Data</th>
											<th className="text-right">New Data</th>
										</thead>
										<tbody>
											<tr>
												<td className="text-left">
													Status Transaction
												</td>
												<td className="text-left">
													<button
														style={
															data.paymentStatus === 1
																? { background: '#E8F5E9' }
																: data.paymentStatus === 2
																? { background: '#FFF3E0' }
																: { background: '#FFF3E0' }
														}
														className={`py-1 px-3 text-xs capitalize rounded-2xl`}
													>
														{data.paymentStatus === 1 ? (
															<p
																className=" font-semibold"
																style={{ color: '#5AB55E' }}
															>
																{data.paymentStatusDesc}
															</p>
														) : data.paymentStatus === 2 ? (
															<p
																className=" font-semibold"
																style={{ color: '#FFAA2C' }}
															>
																{data.paymentStatusDesc}
															</p>
														) : (
															<p
																className=" font-semibold"
																style={{ color: '#F5574C' }}
															>
																{data.paymentStatusDesc}
															</p>
														)}
													</button>
												</td>
												<td className="text-right">
													<button
														style={
															data.newTrxData.paymentStatus === 1
																? { background: '#E8F5E9' }
																: data.newTrxData.paymentStatus === 2
																? { background: '#FFF3E0' }
																: { background: '#FFF3E0' }
														}
														className={`py-1 px-3 text-xs capitalize rounded-2xl`}
													>
														{data.newTrxData.paymentStatus === 1 ? (
															<p
																className=" font-semibold"
																style={{ color: '#5AB55E' }}
															>
																{data.newTrxData.paymentStatusDesc}
															</p>
														) : data.newTrxData.paymentStatus ===
														  2 ? (
															<p
																className=" font-semibold"
																style={{ color: '#FFAA2C' }}
															>
																{data.newTrxData.paymentStatusDesc}
															</p>
														) : (
															<p
																className=" font-semibold"
																style={{ color: '#F5574C' }}
															>
																{data.newTrxData.paymentStatusDesc}
															</p>
														)}
													</button>
												</td>
											</tr>
											<tr>
												<td className="text-left">Invoice Number</td>
												<td className="text-left">{data.noHeader}</td>
												<td className="text-right">
													{data.newTrxData.noHeader}
												</td>
											</tr>
											<tr>
												<td className="text-left">
													Merchant Reference
												</td>
												<td className="text-left">
													{data.merchantNoRef}
												</td>
												<td className="text-right">
													{data.newTrxData.merchantNoRef}
												</td>
											</tr>
											<tr>
												<td className="text-left">Created At</td>
												<td className="text-left">
													{data.createdAt}
												</td>
												<td className="text-right">
													{data.newTrxData.createdAt}
												</td>
											</tr>
											<tr>
												<td className="text-left">Amount</td>
												<td className="text-left">
													{data.paymentAmount.format()}
												</td>
												<td className="text-right">
													{data.newTrxData.paymentAmount.format()}
												</td>
											</tr>
											<tr>
												<td className="text-left">Corporate</td>
												<td className="text-left">
													{data.corporateCID +
														' - ' +
														data.corporateName}
												</td>
												<td className="text-right">
													{data.newTrxData.corporateCID +
														' - ' +
														data.newTrxData.corporateName}
												</td>
											</tr>
											<tr>
												<td className="text-left">Payment Name</td>
												<td className="text-left">
													{data.paymentMethodName}
												</td>
												<td className="text-right">
													{data.newTrxData.paymentMethodName}
												</td>
											</tr>
										</tbody>
									</table>
								)}
							</div>

							<div>
								<p className="font-semibold mt-2">Remarks:</p>
								<p>{data?.remarks ?? ''}</p>
							</div>

							<div>
								<p className="font-semibold mt-2">Add Note:</p>
								<TextField
									className="w-full"
									required
									id="note"
									variant="outlined"
									onChange={(text) => setAddNote(text.target.value)}
									placeholder="note:.."
									InputLabelProps={{ shrink: true }}
									value={addNote}
									defaultValue={addNote}
								/>
							</div>
						</div>
					</Box>
					<div className="w-full flex flex-row justify-between items-center mt-3">
						<div className="self-start flex flex-row items-center gap-2">
							<Button
								bgColor={'#ccc'}
								customFunc={() => setModalReject('cancel')}
								color={'white'}
								text={'Cancel'}
							/>

							<Button
								bgColor={'#F5574C'}
								customFunc={() => setModalReject('reject')}
								color={'white'}
								text={'Reject'}
							/>
						</div>

						<Button
							bgColor={'#5AB55E'}
							customFunc={() => {
								setModalConfirm(true);
							}}
							color={'white'}
							text={'Approve'}
						/>
					</div>
				</DialogContent>
			</Dialog>

			<ModalConfirmation
				isModalOpen={modalConfirm}
				message="Do you agree with this change?"
				title="Confirmation"
				onCancel={() => setModalConfirm(false)}
				onConfirm={() => getConfirmTransaction('success')}
			/>

			<ModalConfirmation
				isModalOpen={modalReject != null}
				message={`Do you want to ${
					modalReject == 'cancel' ? 'cancel' : 'reject'
				} this change?`}
				title={modalReject == 'reject' ? 'Reject' : 'Cancel'}
				onCancel={() => setModalReject(null)}
				onConfirm={() => getConfirmTransaction(modalReject)}
			/>
		</>
	);
};
