import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useStateContext } from '../contexts/ContextProvider';
import Modal from '@mui/material/Modal';
import { Button } from '../components';
import ToggleText from '../components/ToggleText';
import { checkIsAvailableModule } from '../Routes';
import { Tooltip } from '@mui/material';
import {
	CheckCircle,
	Edit,
	InfoRounded,
	QuestionMarkRounded,
} from '@mui/icons-material';
import { FaMinusCircle } from 'react-icons/fa';

var CryptoJS = require('crypto-js');

//List Transaction Column
export const listTransactionColumn = (
	dataCorporate,
	statusData,
	onSelected,
	privilege,
	isVoidDebit = false
) => {
	let m = [
		...[
			{
				name: 'Invoice Number',
				selector: 'noHeader',
				sortable: true,
				width: 300,
			},
			{
				name: 'Last Status', // use template
				selector: 'paymentStatus',
				sortable: true,
				cell: (row) => (
					<button
						style={
							row.paymentStatus === 1
								? { background: '#E8F5E9' }
								: row.paymentStatus === 2
								? { background: '#FFF3E0' }
								: { background: '#FFF3E0' }
						}
						className={`py-1 px-3 text-xs capitalize rounded-2xl`}
					>
						{row.paymentStatus === 1 ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								{row.paymentStatusDesc}
							</p>
						) : row.paymentStatus === 2 ? (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								{row.paymentStatusDesc}
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#F5574C' }}
							>
								{row.paymentStatusDesc}
							</p>
						)}
					</button>
				),
				width: 150,
			},

			{
				name: 'Request Status', // use template
				selector: 'newTrxData',
				sortable: true,
				cell: (row) => (
					<button
						style={
							row.newTrxData.paymentStatus === 1
								? { background: '#E8F5E9' }
								: row.newTrxData.paymentStatus === 2
								? { background: '#FFF3E0' }
								: { background: '#FFF3E0' }
						}
						className={`py-1 px-3 text-xs capitalize rounded-2xl`}
					>
						{row.newTrxData.paymentStatus === 1 ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								{row.newTrxData.paymentStatusDesc}
							</p>
						) : row.newTrxData.paymentStatus === 2 ? (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								{row.newTrxData.paymentStatusDesc}
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#F5574C' }}
							>
								{row.newTrxData.paymentStatusDesc}
							</p>
						)}
					</button>
				),
				width: 150,
			},
			{
				name: 'Merchant Reference',
				selector: 'merchantNoRef',
				sortable: true,
				width: 300,
			},
			{
				name: 'Created Date',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[0] : ''}</p>
					</div>
				),
			},
			{
				name: 'Created Time',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[1] : ''}</p>
					</div>
				),
			},
			{
				name: 'Amount',
				selector: 'paymentAmount',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.paymentAmount.format()}</p>
					</div>
				),
			},
			{
				name: 'Corporate',
				selector: 'corporateCID',
				sortable: true,
				width: 300,
				cell: (row) => (
					<div>
						<p>{row.corporateCID + ' - ' + row.corporateName}</p>
					</div>
				),
			},
			{
				name: 'Payment Category',
				selector: 'paymentCategoryName',
				sortable: true,
				width: 200,
			},
			{
				name: 'Payment Name',
				selector: 'paymentMethodName',
				sortable: true,
				width: 200,
			},
		],
		...(checkIsAvailableModule('REPORT_DETAIL_ACQUIRING', privilege)
			? [
					{
						name: 'Acquiring Code',
						selector: 'acquiringCode',
						sortable: true,
						width: 200,
					},
					{
						name: 'Acquiring Name',
						selector: 'acquiringName',
						sortable: true,
						width: 200,
					},
			  ]
			: []),
		...[
			{
				name: 'Settle. Dest.',
				selector: 'settlementDestination',
				width: 150,
			},
			{
				name: 'Settle Status',
				selector: 'statusSettlement',
				cell: (row) => (
					<button
						style={
							row.statusSettlement === true
								? { background: '#E8F5E9' }
								: { background: '#FFF3E0' }
						}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						{row.statusSettlement === true ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								Yes
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								No
							</p>
						)}
					</button>
				),
				width: 160,
			},
			// {
			// 	name: 'Bank Response Code',
			// 	selector: 'bankResponseCode',
			// 	width: 180,
			// },
			// {
			// 	name: 'Bank Response Message',
			// 	selector: 'bankResponseDesc',
			// 	width: 180,
			// },
			// {
			// 	name: 'Prinpciple Ref',
			// 	selector: 'responseNoRef',
			// 	sortable: true,
			// 	width: 350,
			// 	cell: (row) => (
			// 		<ToggleText
			// 			minToggle={100}
			// 			text={row.responseNoRef ? row.responseNoRef : ''}
			// 		/>
			// 	),
			// },
			{
				name: 'Device ID',
				selector: 'deviceId',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText
						minToggle={18}
						text={row.deviceId ? row.deviceId : ''}
					/>
				),
			},
			{
				name: 'MID',
				selector: 'bankMID',
				sortable: true,
				width: 150,
			},
			{
				name: 'TID',
				selector: 'bankTID',
				sortable: true,
				width: 140,
			},
			{
				name: 'Card Pan',
				selector: 'cardPan',
				sortable: true,
				width: 180,
			},
			{
				name: 'Card Type',
				selector: 'cardType',
				sortable: true,
				width: 180,
			},
			{
				name: 'STAN',
				selector: 'stan',
				width: 180,
			},
			{
				name: 'RRN',
				selector: 'rrn',
				width: 180,
			},
			{
				name: 'Approval Code',
				selector: 'bankApprovalCode',
				width: 180,
			},
			{
				name: 'Onus Offus',
				selector: 'cardTypeTrx',
				width: 100,
			},
			{
				name: 'MDR Total',
				selector: 'paymentMDR',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.paymentMDR.format()}</p>
					</div>
				),
			},
			{
				name: 'Service Fee',
				selector: 'serviceFee',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.serviceFee.format()}</p>
					</div>
				),
			},
			{
				name: 'Payment Fee',
				selector: 'paymentFee',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.paymentFee.format()}</p>
					</div>
				),
			},
			{
				name: 'Vendor Fee',
				selector: 'vendorFee',
				sortable: true,
				width: 140,
				cell: (row) => (
					<div>
						<p>Rp. {row.vendorFee.format()}</p>
					</div>
				),
			},
			{
				name: 'Description',
				selector: 'description',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText minToggle={150} text={row.description ?? ''} />
				),
			},
			{
				name: 'Status Description',
				selector: '',
				sortable: true,
				width: 140,
				cell: (row) => {
					let mFilter = statusData.filter(
						(m) => m.errorsCode == row.statusCode
					);
					return (
						<ToggleText
							minToggle={18}
							text={
								mFilter.length > 0
									? mFilter[0].errorsReadable
									: 'Unknown'
							}
						/>
					);
				},
			},
			{
				name: 'Requested By',
				selector: 'requestedBy',
				width: 200,
			},
			{
				name: 'Requested At',
				selector: 'requestedDatetime',
				width: 200,
			},
			{
				name: 'Approved By',
				selector: 'approvedBy',
				width: 200,
			},
			{
				name: 'Approved At',
				selector: 'approvedAt',
				width: 200,
			},
		],
	];

	// if (
	// 	checkIsAvailableModule('REPORT_DETAIL_TRANSACTION', privilege) ||
	// 	(checkIsAvailableModule('REPORT_VOID_DEBIT', privilege) &&
	// 		isVoidDebit)
	// ) {
	m.unshift({
		name: 'Action', //useTemplate
		selector: '',
		sortable: true,
		width: 200,
		cell: (row) => ListTrxAction(row, onSelected, privilege),
	});
	// }

	return m;
};

const ListTrxAction = (row, onSelected, privilege) => {
	return (
		<div>
			{checkIsAvailableModule(
				'REPORT_DETAIL_TRANSACTION',
				privilege
			) && (
				// <Tooltip title="SEE DETAILS">
				// 	<button
				// 		className=" text-2xl text-blue-500"
				// 		onClick={() => onSelected(row.id)}
				// 	>
				// 		<MdRemoveRedEye />
				// 	</button>
				// </Tooltip>
				<button
					style={
						row.requestStatus === 'APPROVED'
							? { background: '#E8F5E9' }
							: row.requestStatus === 'PENDING'
							? checkIsAvailableModule(
									'TRX_UPDATE_APPROVAL',
									privilege
							  )
								? { background: 'transparent' }
								: { background: '#FFF3E0' }
							: row.requestStatus === 'CANCELLED'
							? { background: '#f7f7f7' }
							: { background: '#FFF3E0' }
					}
					className={`py-1 px-3 text-xs capitalize rounded-2xl`}
				>
					{row.requestStatus === 'APPROVED' ? (
						<Tooltip
							title={row.remarks}
							className="justify-center items-center flex gap-1"
						>
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								{row.requestStatus}

								<CheckCircle fontSize="small" />
							</p>
						</Tooltip>
					) : row.requestStatus === 'PENDING' ? (
						checkIsAvailableModule(
							'TRX_UPDATE_APPROVAL',
							privilege
						) ? (
							<div
								onClick={() => onSelected(row)}
								className="flex justify-start items-center gap-1"
							>
								<p
									className=" font-semibold"
									// style={{ color: '#FFAA2C' }}
								>
									{'View Details'}
								</p>

								<Edit fontSize="small" />
							</div>
						) : (
							<Tooltip
								title={row.remarks}
								className="justify-center items-center flex gap-1"
							>
								<p
									className=" font-semibold"
									style={{ color: '#FFAA2C' }}
								>
									{row.requestStatus}

									<QuestionMarkRounded fontSize="small" />
								</p>
							</Tooltip>
						)
					) : row.requestStatus === 'CANCELLED' ? (
						<Tooltip
							title={row.remarks}
							className="justify-center items-center flex gap-1"
						>
							<p className=" font-semibold" style={{ color: '#999' }}>
								{row.requestStatus}

								<FaMinusCircle fontSize="small" />
							</p>
						</Tooltip>
					) : (
						<Tooltip
							title={row.remarks}
							className="gap-1 justify-center items-center flex"
						>
							<p
								className=" font-semibold"
								style={{ color: '#F5574C' }}
							>
								{row.requestStatus}

								<InfoRounded fontSize="small" />
							</p>
						</Tooltip>
					)}
				</button>
			)}
		</div>
	);
};

// list Product from Transaction
export const listTrxProductDetail = [
	{
		name: 'Kode Produk',
		selector: 'kodeProduk',
		sortable: false,
		width: 130,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Nama Produk',
		selector: 'namaProduk',
		sortable: false,
		width: 200,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Kategori',
		selector: 'kategori',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Paket',
		selector: 'paket',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Harga Jual',
		selector: 'hargaJual',
		sortable: false,
		width: 140,
		cell: (row, index) => (
			<p className="">Rp. {row.hargaJual.format()}</p>
		),
	},
	{
		name: 'Diskon',
		selector: 'diskon',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Kuantitas',
		selector: 'qty',
		sortable: false,
		width: 110,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Satuan',
		selector: 'satuan',
		sortable: false,
		width: 100,
		// cell: (row, index) => <p className=" w-auto">{row.id}</p>,
	},
	{
		name: 'Service Fee',
		selector: 'serviceFee',
		sortable: false,
		width: 130,
		cell: (row, index) => (
			<p className=" w-auto">Rp. {row.serviceFee.format()}</p>
		),
	},
];

// list corporate Column
export const corpColumn = [
	{
		name: 'Action', //useTemplate
		selector: '',
		sortable: true,
		width: 100,
		cell: (row) => CorpAction(row),
	},
	{
		name: 'CID',
		selector: 'cid',
		sortable: true,
		width: 130,
	},
	{
		name: 'Uraian',
		selector: 'uraian',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Kota',
		selector: 'namaKota',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Provinsi',
		selector: 'namaProvinsi',
		sortable: true,
		width: 160,
	},
	{
		name: 'Alamat',
		selector: 'alamat',
		sortable: true,
		width: 250,
	},
	{
		name: 'Telepon',
		selector: 'telepon',
		sortable: true,
		width: 130,
	},
	{
		name: 'Level',
		selector: 'level',
		sortable: true,
		width: 130,
	},
	{
		name: 'Gambar',
		selector: 'gambar',
		sortable: true,
		width: 130,
	},
	{
		name: 'Hirarki',
		selector: 'hirarkiId',
		sortable: true,
		width: 400,
	},
	{
		name: 'IP Local Server',
		selector: 'ipLocalServer',
		sortable: true,
		width: 160,
	},
	{
		name: 'Satuan Percentage',
		selector: 'isPrecentage',
		sortable: true,
		width: 160,
		cell: (row) => (
			<button
				type="button"
				// style={{background:'red'}}
				style={
					row.isPercentage === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl ${
					row.ispercentage === true ? '' : ''
				}`}
			>
				{row.isPercentage === true ? (
					<p style={{ color: '#2699F3' }}> Presentase</p>
				) : (
					<p style={{ color: '#826054' }}>Rupiah</p>
				)}
			</button>
		),
	},
];

export const corpColumnNoAction = [
	{
		name: 'CID',
		selector: 'cid',
		sortable: true,
		width: 130,
	},
	{
		name: 'Uraian',
		selector: 'uraian',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Kota',
		selector: 'namaKota',
		sortable: true,
		width: 250,
	},
	{
		name: 'Nama Provinsi',
		selector: 'namaProvinsi',
		sortable: true,
		width: 160,
	},
	{
		name: 'Alamat',
		selector: 'alamat',
		sortable: true,
		width: 250,
	},
	{
		name: 'Telepon',
		selector: 'telepon',
		sortable: true,
		width: 130,
	},
	{
		name: 'Level',
		selector: 'level',
		sortable: true,
		width: 130,
	},
	{
		name: 'Gambar',
		selector: 'gambar',
		sortable: true,
		width: 130,
	},
	{
		name: 'Hirarki',
		selector: 'hirarkiId',
		sortable: true,
		width: 400,
	},
	{
		name: 'IP Local Server',
		selector: 'ipLocalServer',
		sortable: true,
		width: 160,
	},
	{
		name: 'Satuan Percentage',
		selector: 'isPrecentage',
		sortable: true,
		width: 160,
		cell: (row) => (
			<button
				type="button"
				// style={{background:'red'}}
				style={
					row.isPercentage === true
						? { background: '#E1F5FE' }
						: { background: '#EFEBE9' }
				}
				className={`py-2 px-3 text-sm capitalize rounded-2xl ${
					row.ispercentage === true ? '' : ''
				}`}
			>
				{row.isPercentage === true ? (
					<p style={{ color: '#2699F3' }}> Presentase</p>
				) : (
					<p style={{ color: '#826054' }}>Rupiah</p>
				)}
			</button>
		),
	},
];

const CorpAction = (row) => {
	const { currentAuth, privilege } = useStateContext();

	// Encrypt
	const ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(row.id),
		'nasirames'
	).toString();

	var enc = ciphertext
		.replace(/\+/g, 'p1L2u3S')
		.replace(/\//g, 's1L2a3S4h')
		.replace(/=/g, 'e1Q2u3A4l');

	const editCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Update';
	});

	const deleteCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Delete';
	});

	const notify = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
			onClose: () => window.location.reload(),
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
			// onClose: () => navigate(-1),
		});
	};
	const navigate = useNavigate();
	const deleteData = (id) => {
		const inputData = {
			id: parseInt(id),
		};
		fetch(BASE_URL + '/corporate/remove', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				// 'Authorization' : `Bearer ${token}`
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					notify(json.message);
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};
	const clicked = () => {
		alert('MKP E TICKETING');
	};

	const [modal, setModal] = useState(false);
	return (
		<div className="flex gap-2">
			<button
				type="button"
				onClick={() => navigate(`/update-corp/${enc}`)}
				className=" text-2xl text-green-500 hover:drop-shadow-xl"
			>
				<MdEdit />
			</button>

			<button
				disabled={row.id === 1 ? true : false}
				// onClick={() => alert(encryptedString)}
				onClick={() => setModal(true)}
				className=" text-2xl text-red-500 hover:drop-shadow-xl"
			>
				<HiTrash />
			</button>

			<Modal onBackdropClick={() => setModal(false)} open={modal}>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold text-base md:text-xl">
						This data will be deleted, <br />
						are you sure?
					</p>
					<div className=" flex justify-center gap-5 mt-5 ">
						<Button
							customFunc={() => setModal(false)}
							bgColor={'gray'}
							borderRadius={'10px'}
							color="white"
							text="Cancel"
							// icon={<MdArrowBack />}
						/>
						<Button
							customFunc={() => deleteData(row.id)}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Continue"
							color={'white'}
							// icon={<MdArrowBack />}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
