// import React, { Component } from 'react';
import LogoMKP from '../../data/A2Plogo.png';
import HeaderPDF from '../../data/HeaderPDF.png';

import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	body: {
		paddingLeft: 40,
		paddingRight: 40,
		paddingTop: 20,
		paddingBottom: 20,
		width: '100%',
	},
	headerSection: {
		paddingBottom: 0,
		borderBottom: '1px solid #C2C2C2',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		marginBottom: 10,
	},
	title: {
		fontSize: 30,
		textAlign: 'center',
		fontWeight: 'bold',
	},
	textHeader: { color: '#585AED', fontSize: 12 },
	textHeader2: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	labelText: {
		fontSize: 12,
		color: '#757575',
		flex: 1,
	},
	valueText: {
		fontSize: 12,
		color: '#505050',
		textAlign: 'right',
	},
	labelBoxText: {
		fontSize: 11,
		color: '#757575',
	},
	valueBoxText: {
		fontSize: 11,
		color: '#505050',
		textAlign: 'right',
		flex: 1,
	},
	text2: {
		top: 5,
		margin: 12,
		fontSize: 11,
		textAlign: 'justify',
		left: 96,
		marginLeft: 96,
		position: 'absolute',
		width: 400,
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 100,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	contentSection: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		gap: 10,
		width: '100%',
		paddingTop: 10,
		paddingBottom: 10,
		height: 'auto',
	},
	rowSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		gap: 10,
	},
	rowSection2: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		width: '100%',
	},
	rowSection3: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		width: '100%',
	},
	sectionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		gap: 10,
		marginBottom: 10,
	},
	sectionBoxContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flex: 1,
		backgroundColor: '#F2F2FE',
		padding: 20,
		borderRadius: 10,
		gap: 5,
	},
	sectionTableBoxContainer: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 10,
		width: '100%',
		height: 'auto',
		overflow: 'hidden',
	},
	sectionHeaderBoxContainer: {
		backgroundColor: '#8182F4',
		height: 'auto',
		height: 30,
		paddingLeft: 20,
		paddingRight: 20,
		width: '100%',
	},
	sectionBox2Container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flex: 1,
		backgroundColor: '#F2F2FE',
		padding: '20px 20px',
		gap: 5,
	},
});

const DocumentPDF = (data) => {
	let getCustomer = () => {
		if (data) {
			let mCustomer = data.requestCustomerData;

			try {
				return JSON.parse(mCustomer);
			} catch {
				return null;
			}
		} else {
			return null;
		}
	};

	let getPaymentDetail = (data) => {
		if (
			data &&
			data.transactionDetail &&
			data.transactionDetail.length > 0
		) {
			try {
				return JSON.parse(data.transactionDetail[0].customerData);
			} catch {
				return null;
			}
		}

		return null;
	};

	let getPayment = (data) => {
		if (
			data &&
			data.transactionDetail &&
			data.transactionDetail.length > 0
		) {
			return data.transactionDetail[0];
		}

		return null;
	};

	return (
		<Document>
			<Page>
				<View style={styles.body}>
					<View style={styles.headerSection}>
						<Text style={styles.title}>INVOICE</Text>
						<Image
							src={LogoMKP}
							style={{
								width: 'auto',
								height: 80,
								alignSelf: 'center',
							}}
						/>
					</View>
					<View style={styles.contentSection}>
						<View style={styles.sectionContainer}>
							<View style={styles.rowSection2}>
								<Text style={styles.labelText}>Invoice Number:</Text>
								<Text style={styles.valueText}>
									{data?.merchantNoRef ?? ''}
								</Text>
							</View>
							<View style={{ width: 10 }}></View>
							<View style={styles.rowSection2}>
								<Text style={styles.labelText}>Expired Date:</Text>
								<Text style={styles.valueText}>
									{data?.expPaymentLink ?? ''}
								</Text>
							</View>
						</View>
						<View style={{ height: 20 }}></View>
						<View style={styles.sectionContainer}>
							<View style={styles.rowSection2}>
								<Text style={styles.labelText}>Invoice Date:</Text>
								<Text style={styles.valueText}>
									{data?.createdAt ?? ''}
								</Text>
							</View>
							<View style={{ width: 10 }}></View>
							<View style={styles.rowSection2}>
								<Text style={styles.labelText}>Link Ref:</Text>
								<Text style={styles.valueText}>
									{data?.requestNo ?? ''}
								</Text>
							</View>
						</View>
						<View style={{ height: 70 }}></View>
						<View style={styles.sectionContainer}>
							<View style={styles.sectionBoxContainer}>
								<Text style={styles.textHeader}>Billed To</Text>
								<View style={{ height: 5 }}></View>
								<Text style={styles.textHeader2}>
									{getCustomer(data)?.customerName ?? ''}
								</Text>
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>Email:</Text>
									<Text style={styles.valueBoxText}>
										{getCustomer(data)?.customerInitialEmail ?? ''}
									</Text>
								</View>
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>Phone:</Text>
									<Text style={styles.valueBoxText}>
										{getCustomer(data)?.customerPhoneNumber ?? ''}
									</Text>
								</View>
							</View>
							<View style={{ width: 10 }}></View>

							<View
								style={[
									styles.sectionBoxContainer,
									// { backgroundColor: 'transparent' },
								]}
							>
								<Text style={styles.textHeader}>Paid By</Text>
								<View style={{ height: 5 }}></View>
								<Text style={styles.textHeader2}>
									{getPaymentDetail(data)?.customerName ?? ''}
								</Text>
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>Email:</Text>
									<Text style={styles.valueBoxText}>
										{getPaymentDetail(data)?.customerInitialEmail ??
											''}
									</Text>
								</View>
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>Phone:</Text>
									<Text style={styles.valueBoxText}>
										{getPaymentDetail(data)?.customerPhoneNumber ??
											''}
									</Text>
								</View>
							</View>
						</View>
						<View style={{ height: 70 }}></View>
						<View style={styles.sectionTableBoxContainer}>
							<View style={styles.sectionHeaderBoxContainer}>
								<View style={styles.rowSection3}>
									<Text
										style={[
											styles.labelBoxText,
											{ color: 'white', fontWeight: 'bold' },
										]}
									>
										Description
									</Text>
									<Text
										style={[
											styles.valueBoxText,
											{ color: 'white', fontWeight: 'bold' },
										]}
									>
										Total
									</Text>
								</View>
							</View>

							<View style={styles.sectionBox2Container}>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>
										{data?.remarks ?? ''}
									</Text>
									<Text style={styles.valueBoxText}>
										Rp{data?.amount ? data.amount.format() : '-'}
									</Text>
								</View>
							</View>
						</View>
						<View style={{ height: 70 }}></View>
						<View style={styles.sectionContainer}>
							<View style={styles.sectionBoxContainer}>
								<Text style={styles.textHeader}>Payment Detail</Text>

								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>Payment:</Text>
									<Text style={styles.valueBoxText}>
										{`${getPayment(data)?.paymentCategory}`}
									</Text>
								</View>
								{getPayment(data)?.paymentCategory ==
								'VIRTUAL ACCOUNT' ? (
									<>
										<View style={{ height: 20 }}></View>
										<View style={styles.rowSection3}>
											<Text style={styles.labelBoxText}>
												Payment Name:
											</Text>
											<Text style={styles.valueBoxText}>
												{`${getPayment(data)?.paymentMethodName}`}
											</Text>
										</View>
									</>
								) : (
									<></>
								)}
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>
										Payment Ref:
									</Text>
									<Text style={styles.valueBoxText}>
										{`${getPayment(data)?.apps2payNoheader}`}
									</Text>
								</View>
							</View>
							<View style={{ width: 10 }}></View>
							<View
								style={[
									styles.sectionBoxContainer,
									{
										backgroundColor: 'transparent',
									},
								]}
							>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>Sub Total:</Text>
									<Text style={styles.valueBoxText}>
										Rp{data?.amount ? data.amount.format() : '-'}
									</Text>
								</View>
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text style={styles.labelBoxText}>MDR:</Text>
									<Text style={styles.valueBoxText}>
										Rp{getPayment(data)?.mdr?.format() ?? '-'}
									</Text>
								</View>
								<View style={{ height: 20 }}></View>
								<View
									style={[
										styles.rowSection3,
										{
											borderBottom: '1px solid #C2C2C2',
											height: 5,
											paddingBottom: 5,
										},
									]}
								></View>
								<View style={{ height: 20 }}></View>
								<View style={styles.rowSection3}>
									<Text
										style={[
											styles.labelBoxText,
											{ fontSize: 14, fontWeight: 'bold' },
										]}
									>
										Total
									</Text>
									<Text
										style={[
											styles.valueBoxText,
											{ fontSize: 14, fontWeight: 'bold' },
										]}
									>
										Rp{getPayment(data)?.amount?.format() ?? '-'}
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default DocumentPDF;
