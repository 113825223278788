import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from '@mui/material';
import React, { Component, useEffect, useRef, useState } from 'react';
import { Button } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import CloseIcon from '@mui/icons-material/Close';
import { SearchRounded, WarningSharp } from '@mui/icons-material';
import {
	ModalConfirmation,
	ModalConfirmationUpdateRequest,
} from './ModalConfirmation';
import { BASE_URL, URL_REPORTING_LIST_V2 } from '../../config';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';
import {
	DatePicker,
	LocalizationProvider,
	MobileDatePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import notfound from '../../data/notfound.png';
import DataTable from 'react-data-table-component';
import { listTransactionColumn } from '../../data/columnDataUpdateReq';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

export const ModalUpdateTransactionRequest = (props) => {
	const {
		modalOpenClose,
		dataLogin,
		currentAuth,
		screenWidth,
		privilege,
	} = useStateContext();

	const dateTo = moment().format('YYYY-MM-DD');
	const dateFrom = moment().subtract(1, 'd').format('YYYY-MM-DD');

	const [data, setData] = useState(null);

	const [modalConfirm, setModalConfirm] = useState(null);
	const [modalReject, setModalReject] = useState(null);
	const [addNote, setAddNote] = useState('');
	const [noHeader, setNoheader] = useState('');
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);

	const dateStarted = moment(startDate).format('YYYY-MM-DD');
	const dateEnded = moment(endDate).format('YYYY-MM-DD');
	const dateToMax = moment(startDate).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(startDate).add(1, 'months').format('YYYY-MM-DD');

	const [searchLoading, setSearchLoading] = useState(false);
	const [listTrx, setListTrx] = useState(null);

	const [selectedConfirm, setSelectedConfirm] = useState(null);

	const inputRef = useRef(null);

	useEffect(() => {
		if (props.isModal && props.isModal == false) {
		}
	}, [props.isModal]);

	const getConfirmTransaction = (e = null) => {
		e && e.preventDefault();
		if (noHeader) {
			setSearchLoading(true);

			const bodyData = {
				startDate: dateStarted,
				endDate: dateEnded,
				kategoriPembayaran: '',
				paymentStatus: 0,
				corporate: '',
				hirarkiId: '',
				parameter: 'noHeader',
				cid: '',
				pagination: {
					order: 'desc',
					limit: 1,
					page: 1,
					keyword: noHeader,
					orderBy: '',
				},
			};

			let mTimeStamp = getTimeStamp();
			let bodyEncripted = encryptSHA512(
				JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
			);

			let dataPLain =
				'POST' +
				'||' +
				URL_REPORTING_LIST_V2 +
				'||' +
				(dataLogin.username ?? '') +
				'||' +
				bodyEncripted +
				'||' +
				mTimeStamp;

			let signature = encryptHmac512Base64(dataPLain);

			fetch(BASE_URL + URL_REPORTING_LIST_V2, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${currentAuth}`,
					'X-MKP-Timestamp': mTimeStamp,
					'X-MKP-Signature': signature,
				},
				body: JSON.stringify(bodyData),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					setSearchLoading(false);
					if (data.success) {
						if (data.result != null) {
							setListTrx(data.result);
						} else {
							setListTrx([]);
						}
					} else {
						setListTrx([]);
					}
				})
				.catch((err) => {
					console.log('err =>', err);
					setListTrx([]);
					setSearchLoading(false);
				});
		} else {
			inputRef.current.focus();
		}
	};

	useEffect(() => {
		if (props.isModal == false) {
			setSelectedConfirm(null);
			setModalConfirm(null);
			setNoheader('');
			setListTrx(null);
			setSearchLoading(false);
		}
	}, [props.isModal]);

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={props.isModal}
				onClose={() => {
					setData(null);
					props.onCancel();
				}}
			>
				<DialogTitle color={'green'}>
					<div
						style={{ display: 'flex' }}
						className="justify-start items-center flex-row"
					>
						<p>Add Request</p>
						{/* <MdCheckCircle style={{ marginLeft: 10 }} />{' '} */}
					</div>
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => {
						setData(null);
						props.onCancel();
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<form onSubmit={getConfirmTransaction}>
						<DialogContentText
							style={{ borderBottomWidth: 1, paddingBottom: 3 }}
							className="flex items-center"
						>
							<WarningSharp
								fontSize="small"
								color="error"
								className="mr-1"
							/>
							Make sure you have checked the following transaction
							data.
						</DialogContentText>
						<Box
							noValidate
							component="form"
							sx={{
								display: 'flex',
								flexDirection: 'column',
								m: 'auto',
								width: '100%',
								paddingTop: 3,
							}}
						>
							<div className="d-flex flex-col justify-end align-items-center">
								<div>
									<p className="font-semibold mt-1 mb-3">
										Search Invoice Number:
									</p>
									<div className="flex items-center justify-center gap-2">
										<div className="flex-1">
											<LocalizationProvider
												dateAdapter={AdapterMoment}
											>
												<MobileDatePicker
													className=" w-full"
													label="Start Date"
													value={startDate}
													onChange={(newValue) => {
														setStartDate(newValue);
														setEndDate(newValue);
													}}
													inputFormat="DD-MM-YYYY"
													maxDate={dateTo}
													renderInput={(params) => (
														<TextField {...params} size="small" />
													)}
													PopperProps={{
														placement: 'bottom',
													}}
												/>
											</LocalizationProvider>
										</div>
										{/* end date input */}
										<div className="flex-1">
											<LocalizationProvider
												dateAdapter={AdapterMoment}
											>
												<MobileDatePicker
													className=" w-full"
													label="Until Date"
													value={endDate}
													onChange={(newValue) => {
														setEndDate(newValue);
													}}
													inputFormat="DD-MM-YYYY"
													minDate={startDate}
													maxDate={dateToMax}
													renderInput={(params) => (
														<TextField {...params} size="small" />
													)}
												/>
											</LocalizationProvider>
										</div>

										<div className="flex-1">
											<TextField
												className="w-full"
												// ref={inputRef}
												inputRef={inputRef}
												id="noHeader"
												variant="outlined"
												onChange={(text) =>
													setNoheader(text.target.value)
												}
												placeholder="your invoice number here.."
												InputLabelProps={{ shrink: true }}
												value={noHeader}
												size="small"
												required
											/>
										</div>
									</div>
								</div>
								<div className="w-full mt-3">
									{listTrx != null && (
										<DataTable
											noDataComponent={
												<div className=" justify-center">
													<img
														src={notfound}
														style={{
															width: 'auto',
															height: screenWidth >= 500 ? 400 : 200,
														}}
													/>
												</div>
											}
											columns={listTransactionColumn((row) => {
												setModalConfirm(row);
											}, privilege)}
											data={listTrx}
											pagination
											paginationRowsPerPageOptions={[20, 30, 50, 100]}
											theme="solarized"
											customStyles={customStyles}
										/>
									)}
								</div>
							</div>
						</Box>
						<div className="w-full flex flex-row justify-end items-center mt-3">
							<Button
								bgColor={'#5AB55E'}
								// customFunc={() => {
								// 	getConfirmTransaction();
								// }}
								type="submit"
								color={'white'}
								text={'Search'}
								disabled={searchLoading}
								icon={
									searchLoading ? (
										<CircularProgress
											size={14}
											style={{ color: 'white' }}
										/>
									) : (
										<SearchRounded fontSize="small" />
									)
								}
							/>
						</div>
					</form>
				</DialogContent>
			</Dialog>

			<ModalConfirmationUpdateRequest
				isModalOpen={modalConfirm != null}
				modalData={modalConfirm}
				message="Do you agree with this change?"
				title="Confirmation"
				onCancel={() => setModalConfirm(null)}
				onConfirm={(selectedData) => {
					// getConfirmTransaction('success')
					setSelectedConfirm(selectedData);
				}}
			/>

			<ModalConfirmation
				isModalOpen={selectedConfirm != null}
				title="Confirmation"
				message="Are you sure to request update?"
				onCancel={() => setSelectedConfirm(null)}
				onConfirm={() => {
					let mSelected = selectedConfirm;
					setSelectedConfirm(null);
					setModalConfirm(null);
					setNoheader('');
					setListTrx(null);

					props.onModalConfirm(mSelected);
				}}
			/>
		</>
	);
};
