import React, { useState } from 'react';
import {
	MdOutlineRestorePage,
	MdRemoveRedEye,
	MdContentCopy,
	MdPrint,
} from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';

import { Popover, Tooltip, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { useStateContext } from '../../contexts/ContextProvider';
import document from './pdfExport';
import DocumentPDF from './pdfExport';

//List Transaction Column
export const paymentLinkListColumn = (
	statusData,
	onSelected,
	privilege,
	isVoidDebit = false
) => {
	let m = [
		...[
			{
				name: 'Last Payment Status', // use template
				selector: 'transactionDetail',
				sortable: true,
				cell: (row) => {
					if (
						row.transactionDetail &&
						row.transactionDetail.length > 0
					) {
						return (
							<>
								<button
									style={
										row.transactionDetail[0].paymentStatusCode == '1'
											? { background: '#E8F5E9' }
											: row.transactionDetail[0].paymentStatusCode ==
													'00' &&
											  row.transactionDetail[0].paymentStatus ==
													'INQUIRY SUCCESS'
											? { background: '#FFF3E0' }
											: { background: '#FFF3E0' }
									}
									className={`py-2 px-3 text-sm capitalize rounded-2xl`}
								>
									{row.transactionDetail[0].paymentStatusCode ==
									'1' ? (
										<p
											className=" font-semibold"
											style={{ color: '#5AB55E' }}
										>
											{'SUCCESS'}
										</p>
									) : row.transactionDetail[0].paymentStatusCode ==
											'00' &&
									  row.transactionDetail[0].paymentStatus ==
											'INQUIRY SUCCESS' ? (
										<p
											className=" font-semibold"
											style={{ color: '#FFAA2C' }}
										>
											{'PENDING'}
										</p>
									) : (
										<p
											className=" font-semibold"
											style={{ color: '#F5574C' }}
										>
											{row.transactionDetail[0].paymentStatus?.toUpperCase()}
										</p>
									)}
								</button>
								{/* <p className="ml-2">
									{row.transactionDetail[0]
										? row.transactionDetail[0].paymentCategory
										: ''}
								</p> */}
							</>
						);
					} else {
						return (
							<button
								style={{ background: '#F0F6F8' }}
								className={`py-2 px-3 text-sm capitalize rounded-2xl`}
							>
								<p
									className=" font-semibold"
									style={{ color: '#2E5190' }}
								>
									{'Not Available'}
								</p>
							</button>
						);
					}
				},
				width: 250,
			},
			{
				name: 'Last Payment Category',
				selector: 'merchantNoRef',
				sortable: true,
				width: 250,
				cell: (row) => (
					<div>
						{row.transactionDetail &&
						row.transactionDetail.length > 0 ? (
							<p>
								{row.transactionDetail[0]
									? row.transactionDetail[0].paymentCategory
									: '-'}
							</p>
						) : (
							<p>{'-'}</p>
						)}
					</div>
				),
			},
			{
				name: 'Link Status',
				selector: 'paymentLinkStatus',
				sortable: true,
				width: 300,
				cell: (row) => {
					return (
						<button
							style={
								row.paymentLinkStatus == 'ACTIVE' ||
								row.paymentLinkStatus == 'COMPLETED'
									? { background: '#E8F5E9' }
									: { background: '#FFF3E0' }
							}
							className={`py-2 px-3 text-sm capitalize rounded-2xl`}
						>
							<p
								className=" font-semibold"
								style={{
									color:
										row.paymentLinkStatus == 'ACTIVE' ||
										row.paymentLinkStatus == 'COMPLETED'
											? '#5AB55E'
											: '#F5574C',
								}}
							>
								{row.paymentLinkStatus}
							</p>
						</button>
					);
				},
			},
			{
				name: 'Invoice Number',
				selector: 'merchantNoRef',
				sortable: true,
				width: 300,
			},
			{
				name: 'Link Reference',
				selector: 'requestNo',
				sortable: true,
				width: 300,
				cell: (row) => {
					return (
						<>
							<p className=" font-semibold mr-2">{row.requestNo}</p>

							{row.paymentLinkStatus == 'ACTIVE' ||
							row.paymentLinkStatus == 'COMPLETED' ? (
								<Enc
									text={row.url}
									context={'Link ' + row.requestNo}
									copyContext={'Copy Url'}
								/>
							) : (
								<></>
							)}
						</>
					);
				},
			},
			{
				name: 'Created Date',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[0] : ''}</p>
					</div>
				),
			},
			{
				name: 'Created Time',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[1] : ''}</p>
					</div>
				),
			},
			{
				name: 'Expired Link',
				selector: 'expPaymentLink',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>
							{row.expPaymentLink
								? moment(row.expPaymentLink).format(
										'DD-MM-YYYY HH:mm:ss'
								  )
								: ''}
						</p>
					</div>
				),
			},
			{
				name: 'Amount',
				selector: 'paymentAmount',
				sortable: true,
				width: 180,
				cell: (row) => (
					<div className="flex flex-row justify-center align-items-center">
						<p>Rp. {row.amount ? row.amount.format() : '-'}</p>
					</div>
				),
			},
			{
				name: 'Corporate',
				selector: 'corporateCID',
				sortable: true,
				width: 300,
				cell: (row) => (
					<div>
						<p>{row.corporateCID + ' - ' + row.corporateName}</p>
					</div>
				),
			},
			{
				name: 'Corporate Email',
				selector: 'requestCustomerData',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>
							{row.requestCustomerData
								? JSON.parse(row.requestCustomerData)
										.customerInitialEmail
								: ' - '}
						</p>
					</div>
				),
			},
			{
				name: 'Corporate Phone Number',
				selector: 'requestCustomerData',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>
							{row.requestCustomerData
								? JSON.parse(row.requestCustomerData)
										.customerPhoneNumber
								: ' - '}
						</p>
					</div>
				),
			},
			{
				name: 'Corporate Account',
				selector: 'merchantkeyUsername',
				sortable: true,
				width: 200,
			},
		],
	];

	// if (
	// 	checkIsAvailableModule('REPORT_DETAIL_TRANSACTION', privilege) ||
	// 	(checkIsAvailableModule('REPORT_VOID_DEBIT', privilege) &&
	// 		isVoidDebit)
	// ) {
	m.unshift({
		name: 'Action', //useTemplate
		selector: '',
		sortable: true,
		width: 100,
		cell: (row) => ListTrxAction(row, onSelected, privilege),
	});
	// }

	return m;
};

const ListTrxAction = (row, onSelected, privilege) => {
	const { currentColor } = useStateContext();

	return (
		<div className="flex flex-row justify-start items-center gap-2">
			<Tooltip title="See Detail">
				<button
					className=" text-2xl text-blue-500"
					onClick={() => onSelected('detail', row)}
				>
					<MdRemoveRedEye />
				</button>
			</Tooltip>
			{/* )} */}
			{row.paymentLinkStatus == 'COMPLETED' ? (
				<Tooltip title="Export PDF">
					<PDFDownloadLink
						document={DocumentPDF(row)}
						fileName={`Invoice_${row.merchantNoRef}_${row.createdAt}`}
					>
						<button
							// onClick={() => onSelected('export_pdf', row)}
							className=" text-2xl text-blue-500"
						>
							<MdPrint size={22} color="#F5574C" />
						</button>
					</PDFDownloadLink>
				</Tooltip>
			) : (
				<></>
			)}
		</div>
	);
};

export const Enc = (props) => {
	const [openPop, setOpenPop] = useState(false);

	return (
		<div className="flex">
			<Tooltip title={props.copyContext}>
				<div>
					<CopyToClipboard text={props.text}>
						<button
							onClick={() => setOpenPop(true)}
							type="button"
							className=" text-2xl hover:drop-shadow-xl"
						>
							<MdContentCopy />
						</button>
					</CopyToClipboard>
				</div>
			</Tooltip>
			<Popover
				open={openPop}
				onClose={() => setOpenPop(false)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography sx={{ p: 2 }}>
					{props.context} copied to clipboard!
				</Typography>
			</Popover>
		</div>
	);
};

export const paymentInvoiceListColumn = (
	statusData,
	onSelected,
	privilege,
	isVoidDebit = false
) => {
	let m = [
		...[
			{
				name: 'Status', // use template
				selector: 'transactionDetail',
				sortable: true,
				cell: (row) => {
					if (row.paymentStatusCode) {
						return (
							<>
								<button
									style={
										row.paymentStatusCode == '1'
											? { background: '#E8F5E9' }
											: row.paymentStatusCode == '00' &&
											  row.paymentStatus == 'INQUIRY SUCCESS'
											? { background: '#FFF3E0' }
											: { background: '#FFF3E0' }
									}
									className={`py-2 px-3 text-sm capitalize rounded-2xl`}
								>
									{row.paymentStatusCode == '1' ? (
										<p
											className=" font-semibold"
											style={{ color: '#5AB55E' }}
										>
											{'SUCCESS'}
										</p>
									) : row.paymentStatusCode == '00' &&
									  row.paymentStatus == 'INQUIRY SUCCESS' ? (
										<p
											className=" font-semibold"
											style={{ color: '#FFAA2C' }}
										>
											{'PENDING'}
										</p>
									) : (
										<p
											className=" font-semibold"
											style={{ color: '#F5574C' }}
										>
											{row.paymentStatus?.toUpperCase()}
										</p>
									)}
								</button>
							</>
						);
					} else {
						return (
							<button
								style={{ background: '#F0F6F8' }}
								className={`py-2 px-3 text-sm capitalize rounded-2xl`}
							>
								<p
									className=" font-semibold"
									style={{ color: '#2E5190' }}
								>
									{'Not Available'}
								</p>
							</button>
						);
					}
				},
				width: 150,
			},
			{
				name: 'Payment Reference',
				selector: 'apps2payNoheader',
				sortable: true,
				width: 300,
				cell: (row) => {
					return row.apps2payNoheader ? (
						<>
							<p className=" font-semibold mr-2">
								{row.apps2payNoheader}
							</p>

							<Enc
								text={row.apps2payNoheader}
								context={'Payment Ref Number'}
								copyContext={'Copy Ref Number'}
							/>
						</>
					) : (
						<>
							<p className=" font-semibold mr-2">-</p>
						</>
					);
				},
			},
			{
				name: 'Payment',
				selector: 'paymentCategory',
				sortable: true,
				width: 150,
			},
			{
				name: 'Payment Code',
				selector: 'paymentCode',
				sortable: true,
				width: 150,
			},
			{
				name: 'Created Date',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[0] : ''}</p>
					</div>
				),
			},
			{
				name: 'Created Time',
				selector: 'createdAt',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>{row.createdAt ? row.createdAt.split(' ')[1] : ''}</p>
					</div>
				),
			},
			{
				name: 'Amount',
				selector: 'paymentAmount',
				sortable: true,
				width: 180,
				cell: (row) => (
					<div className="flex flex-row justify-center align-items-center">
						<p>Rp. {row.amount ? row.amount.format() : '-'}</p>
					</div>
				),
			},
			{
				name: 'Customer Name',
				selector: 'customerData',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>
							{row.customerData
								? JSON.parse(row.customerData).customerName
								: ' - '}
						</p>
					</div>
				),
			},
			{
				name: 'Customer Email',
				selector: 'customerData',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>
							{row.customerData
								? JSON.parse(row.customerData).customerInitialEmail
								: ' - '}
						</p>
					</div>
				),
			},
			{
				name: 'Customer Phone Number',
				selector: 'customerData',
				sortable: true,
				width: 200,
				cell: (row) => (
					<div>
						<p>
							{row.customerData
								? JSON.parse(row.customerData).customerPhoneNumber
								: ' - '}
						</p>
					</div>
				),
			},
			{
				name: 'Card Type',
				selector: 'cardType',
				sortable: true,
				width: 150,
			},
			{
				name: 'Card PAN',
				selector: 'cardPan',
				sortable: true,
				width: 150,
			},
			{
				name: 'MID',
				selector: 'mid',
				sortable: true,
				width: 150,
			},
			{
				name: 'TID',
				selector: 'tid',
				sortable: true,
				width: 150,
			},
			{
				name: 'STAN',
				selector: 'stan',
				sortable: true,
				width: 150,
			},
			{
				name: 'Approval Code',
				selector: 'approvalCode',
				sortable: true,
				width: 150,
			},
		],
	];

	return m;
};

export const paymentItemsListColumn = (isConfirm = false) => {
	let m = [
		...[
			{
				name: 'ID',
				selector: 'productId',
				sortable: true,
				width: 100,
			},
			{
				name: 'Product Name',
				selector: 'productName',
				sortable: true,
				width: 200,
			},
			{
				name: 'Payment Price',
				selector: 'productSinglePrice',
				sortable: true,
				width: 150,
				cell: (row) => (
					<div className="flex flex-row justify-center align-items-center">
						<p>
							Rp.{' '}
							{row.productSinglePrice
								? row.productSinglePrice.format()
								: '-'}
						</p>
					</div>
				),
			},
			{
				name: 'Quantity',
				selector: 'productQuantity',
				sortable: true,
				width: 100,
			},
			{
				name: 'Total',
				selector: 'productTotalPrice',
				sortable: true,
				width: 150,
				cell: (row) => (
					<div className="flex flex-row justify-center align-items-center">
						<p>
							Rp.{' '}
							{row.productTotalPrice
								? row.productTotalPrice.format()
								: '-'}
						</p>
					</div>
				),
			},
		],
		...(!isConfirm
			? [
					{
						name: 'Created At',
						selector: 'createdAt',
						sortable: true,
						width: 200,
					},
			  ]
			: []),
	];

	return m;
};
